import { Link } from 'react-router-dom';
import { useDetailEmployeeContext } from '../Context';
import { ROLE_SUPER_ADMINISTRATOR, RoleAbilities, useAuthentication } from 'Common/Provider/Authentication';
import Icon from 'Common/UI/Component/Icon';
import { humanizeCurrency, humanizeDate, humanizeDateTime, humanizeRelativeFromDate } from 'Common/Utility/humanize';

export default function DetailEmployeeBasicInformation() {
    const { user, role } = useAuthentication()
    const { employee, setActionState, employeeSalaries, selectActionObject } = useDetailEmployeeContext()

    return (
        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                {/* Description list*/}

                <section aria-labelledby="applicant-information-title">
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Person
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Person basic information.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Full Name
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.name}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Phone
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.phoneNumber ?? '-'}
                                    </dd>
                                </div>

                              
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Birth Date
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.bornDate ? humanizeDate(employee.bornDate) : '-'}
                                    </dd>
                                </div>
                                <div className="sm:col-span-2 col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Address
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.address ?? '-'}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                    <div className="bg-white shadow sm:rounded-lg mt-4">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Employee
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Employee basic information.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                              
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        NPWP
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.NPWP ?? '-'}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        NIK
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {employee.NIK ?? '-'}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>


            </div>

            <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1 w-full space-y-4">
                {employee.access && (
                    <div className="bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Access
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Employee access information.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Assined at</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <div className=''>{humanizeDateTime(employee.access.created)}</div>
                                        <div className='font-light text-xs'>{employee.access.created}</div>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        {role === ROLE_SUPER_ADMINISTRATOR &&
                            <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                <button onClick={() => setActionState('invoke-employee')} className="w-0 flex-1 flex">
                                    <a
                                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                    >
                                        <span className="ml-3">Invoke Role</span>
                                    </a>
                                </button>
                            </div>
                        }
                    </div>
                )}
                {!employee.access && (
                    <div className="bg-white shadow sm:rounded-lg opacity-30 hover:opacity-100">
                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Access
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Employee access information.</p>
                        </div>
                        {role === ROLE_SUPER_ADMINISTRATOR &&
                            <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                <button onClick={() => setActionState('assign-account')} className="w-0 flex-1 flex">
                                    <a
                                        className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                    >
                                        <span className="ml-3">Assign Access</span>
                                    </a>
                                </button>
                            </div>
                        }
                    </div>
                )}

                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 w-full">
                    <div className="px-4 py-5 sm:px-6 flex flex-row">
                        <div className='w-full'>
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Daftar Kontak Alternatif
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Kontak selain kontak Utama
                            </p>
                        </div>
                        <div className='flex items-center'>
                            {RoleAbilities.isAbleEditEmployee(user) &&
                                <button onClick={() => setActionState('add-employee-contact')}>
                                    <Icon.IconSolid.PlusCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
                                </button>
                            }
                        </div>
                    </div>
                    <div className="border-t border-gray-200 py-5 ">
                        <div className="bg-white shadow overflow-hidden sm:rounded-md">
                            <ul role="list" className="divide-y divide-gray-200">
                                {employee.contacts.map((contact) => (
                                    <li key={contact.ID}>
                                        <a className="block hover:bg-gray-50">
                                            <div className="flex items-center px-4 py-4 sm:px-6">
                                                <div className="min-w-0 flex-1 flex items-center">
                                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                        <div>
                                                            <p className="text-sm font-medium text-indigo-600 truncate">
                                                                {contact.type}
                                                            </p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                {
                                                                    contact.type === 'E-Mail' ?
                                                                        <Icon.IconSolid.EnvelopeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" /> :
                                                                        <Icon.IconSolid.PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                }
                                                                <span className="">{contact.contact}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-row gap-3'>
                                                    <button onClick={() => selectActionObject(contact) || setActionState('remove-employee-contact')}>
                                                        <Icon.IconSolid.TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 w-full">
                    <div className="px-4 py-5 sm:px-6 flex flex-row">
                        <div className='w-full'>
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Daftar Rekening
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Daftar Rekening karyawan
                            </p>
                        </div>
                        <div className='flex items-center'>
                            {RoleAbilities.isAbleEditEmployee(user) && (
                                <button onClick={() => setActionState('add-employee-bank-account')}>
                                    <Icon.IconSolid.PlusCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="border-t border-gray-200 py-5 ">
                        <div className="bg-white shadow overflow-hidden sm:rounded-md">
                            <ul role="list" className="divide-y divide-gray-200">
                                {employee.bankAccounts.map((bankAccount) => (
                                    <li key={bankAccount.ID}>
                                        <a className="block hover:bg-gray-50">
                                            <div className="flex items-center px-4 py-4 sm:px-6">
                                                <div className="min-w-0 flex-1 flex items-center">
                                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                        <div>
                                                            <p className="text-sm font-medium text-indigo-600 truncate">
                                                                {bankAccount.bank.name}
                                                            </p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                <Icon.IconSolid.BanknotesIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                <span className="">{bankAccount.number}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-row gap-3'>
                                                    {RoleAbilities.isAbleEditEmployee(user) &&
                                                        <button onClick={() => selectActionObject(bankAccount) || setActionState('remove-employee-bank-account')}>
                                                            <Icon.IconSolid.TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 w-full">
                    <div className="px-4 py-5 sm:px-6 flex flex-row">
                        <div className='w-full'>
                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Perubahan Gaji
                            </h2>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Sejarah perubahan gaji dari yang terbaru hingga terlama
                            </p>
                        </div>
                        <div className='flex items-center'>
                            {RoleAbilities.isAbleEditEmployee(user) && (
                                <button onClick={() => setActionState('add-employee-salary')}>
                                    <Icon.IconSolid.PlusCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="border-t border-gray-200 py-5 ">
                        <div className="bg-white shadow overflow-hidden sm:rounded-md">
                            <ul role="list" className="divide-y divide-gray-200">
                                {employeeSalaries.map((employeeSalary, index) => {
                                    const previous = employeeSalaries[index + 1]
                                    const diffTotal = previous ? ((employeeSalary.totalAmount - previous.totalAmount) / previous.totalAmount) : null

                                    return (
                                        <li key={employeeSalary.ID}>
                                            <a className="block hover:bg-gray-50">
                                                <div className="flex items-center px-4 py-4 sm:px-6">
                                                    <div className="min-w-0 flex-1 flex items-center">
                                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                            <div>
                                                                <div className='flex flex-row gap-2 items-center'>
                                                                    <p className="text-sm font-medium text-indigo-600 truncate">
                                                                        {humanizeCurrency(employeeSalary.totalAmount)}

                                                                    </p>

                                                                    {diffTotal === 0 || diffTotal === null ? null :
                                                                        diffTotal > 0 ?
                                                                            <Icon.IconSolid.ArrowTrendingUpIcon className='w-4 h-4 text-lime-400' /> :
                                                                            <Icon.IconSolid.ArrowTrendingDownIcon className='w-4 h-4 text-rose-400' />
                                                                    }
                                                                </div>
                                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                    <Icon.IconSolid.CalendarDaysIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                    <span className="text-xs">
                                                                        {humanizeRelativeFromDate(employeeSalary.created)}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-row gap-3'>
                                                        <button>
                                                            <Icon.IconSolid.TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div>
                                <Link to="salary" className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
                                    Lihat selengkapnya
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}