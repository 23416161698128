import { Card, Flex, Text, Title, Tracker } from "@tremor/react";
import { transformResponseToSingle } from "Common/Utility/formatter";
import { generateArray } from "Common/Utility/generator";
import { humanizePercentage } from "Common/Utility/humanize";
import { reduceSum } from "Common/Utility/math";
import { useCalendarAttendance } from "Core/HumanResource/API/AttendanceAPI";
import { colorBasedOnAttendanceRate } from "Core/HumanResource/Utility/Colorize";
import moment from "moment";

const loading = generateArray(30).map(_ => ({ color: 'gray', tooltip: 'Memuat...' }))

export default function CardMonthAttendanceRate({ title, subtitle, period }) {
    const momentPeriod = period ? moment(period, 'YYYY-MM') : moment()
    const startDate = momentPeriod.startOf('month').format('YYYY-MM-DD')
    const endDate = momentPeriod.endOf('month').format('YYYY-MM-DD')
    const calenderAttendance = useCalendarAttendance({ startDate, endDate, properties: ['attendanceRate'] })
    const calenderAttendances = transformResponseToSingle(calenderAttendance.data)
    const list = calenderAttendance.isLoading ? [] : Object.values(calenderAttendances)
    const data = list.map(attendanceReport => ({
        color: colorBasedOnAttendanceRate(attendanceReport.attendanceRate.percentage),
        tooltip: `${humanizePercentage(attendanceReport.attendanceRate.percentage / 100)}`
    }));
    const average = calenderAttendance.isLoading ? 0 : list.map(item => item.attendanceRate.percentage).reduce(reduceSum) / list.length

    return (
        <Card className="max-w-sm mx-auto">
            <Title>{title}</Title>
            <Text>{subtitle}</Text>
            <Flex justifyContent="end" className="mt-4">
                <Text>Presensi sekitar {humanizePercentage(average / 100)}</Text>
            </Flex>
            <Tracker
                className="mt-2"
                data={calenderAttendance.isLoading ? loading : data}
            />
        </Card>
    )
}