import { TabViewRoutes } from "Common/UI/Block/TabViewRoutes";
import Icon from "Common/UI/Component/Icon";
import { toInitial } from "Common/Utility/formatter";
import { humanizeDateTime, humanizeRelativeFromDate } from "Common/Utility/humanize";
import DetailReportSummarySubRoute from "./DetailReportSummarySubRoute";
import DetailReportFileSubRoute from "./DetailReportFileSubRoute";
import Button from "Common/UI/Component/Button";
import { useDetailReportContext } from "./DetailReportContext";

export default function DetailReportRoute() {
    const { id, report, updateReport, detailReport } = useDetailReportContext()

    if (detailReport.isLoading) {
        return null
    }

    return (
        <main className="flex-1">
            <div className="py-8 xl:py-10">
                <div className="max-w-3xl xl:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:grid xl:grid-cols-3">
                    <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
                        <div>
                            <div>
                                <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                                    <div>
                                        <h1 className="text-2xl font-bold text-gray-900">{report.title}</h1>
                                        <p className="mt-2 text-sm text-gray-500">
                                            #{report.ID} dibuka oleh{' '}
                                            <a href="#" className="font-medium text-gray-900">
                                                {report.createdBy.account.email}
                                            </a>{' '}
                                        </p>
                                    </div>
                                    <div className="mt-4 flex space-x-3 md:mt-0">
                                        {report.status === 'Open' ? null :
                                            <Button.LoadableButton color="sky" icon={Icon.IconPhospor.LockKeyOpen} loading={updateReport.isLoading && updateReport.variables?.status === 'Open'} loadingText="Membuka ..." onClick={() => updateReport.mutate({ ID: id, status: 'Open' })} >
                                                Buka Laporan
                                            </Button.LoadableButton>
                                        }
                                        {report.status === 'Finish' ? null :
                                            <Button.LoadableButton color="lime" icon={Icon.IconPhospor.LockKeyOpen} loading={updateReport.isLoading && updateReport.variables?.status === 'Finish'} loadingText="Menyelesaikan ..." onClick={() => updateReport.mutate({ ID: id, status: 'Finish' })} >
                                                Menyelesaikan Laporan
                                            </Button.LoadableButton>
                                        }
                                        {report.status === 'Reject' ? null :
                                            <Button.LoadableButton variant="secondary" color="rose" icon={Icon.IconPhospor.LockKey} loading={updateReport.isLoading && updateReport.variables?.status === 'Reject'} loadingText="Menutup ..." onClick={() => updateReport.mutate({ ID: id, status: 'Reject' })} >
                                                Menutup Laporan
                                            </Button.LoadableButton>
                                        }
                                    </div>
                                </div>
                                <aside className="mt-8 xl:hidden">
                                    <h2 className="sr-only">Details</h2>
                                    <div className="space-y-5">
                                        {report.status !== 'Open' ? null :
                                            <div className="flex items-center space-x-2">
                                                <Icon.IconSolid.LockOpenIcon className="h-5 w-5 text-sky-500" aria-hidden="true" />
                                                <span className="text-green-700 text-sm font-medium">Proses</span>
                                            </div>
                                        }
                                        {report.status !== 'Reject' ? null :
                                            <div className="flex items-center space-x-2">
                                                <Icon.IconSolid.LockClosedIcon className="h-5 w-5 text-rose-500" aria-hidden="true" />
                                                <span className="text-rose-700 text-sm font-medium">Tolak</span>
                                            </div>
                                        }
                                        {report.status !== 'Finish' ? null :
                                            <div className="flex items-center space-x-2">
                                                <Icon.IconSolid.LockClosedIcon className="h-5 w-5 text-lime-500" aria-hidden="true" />
                                                <span className="text-lime-700 text-sm font-medium">Selesai</span>
                                            </div>
                                        }
                                        <div className="flex items-center space-x-2">
                                            <Icon.IconSolid.ChatBubbleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <span className="text-gray-900 text-sm font-medium">{report.commentCounts} komentar</span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Icon.IconSolid.CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <span className="text-gray-900 text-sm font-medium">
                                                Dilaporkan pada <time dateTime={report.created}>{humanizeRelativeFromDate(report.created)}</time><br />
                                                <span className="text-gray-400">{humanizeDateTime(report.created)}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-6 border-t border-b border-gray-200 py-6 space-y-8">
                                        <div>
                                            <h2 className="text-sm font-medium text-gray-500">Assignees</h2>
                                            <ul role="list" className="mt-3 space-y-3">
                                                <li className="flex justify-start">
                                                    <a href="#" className="flex items-center space-x-3">
                                                        <div className="flex-shrink-0">
                                                            <img
                                                                className="h-5 w-5 rounded-full"
                                                                src="https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="text-sm font-medium text-gray-900">Eduardo Benz</div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h2 className="text-sm font-medium text-gray-500">Tags</h2>
                                            <ul role="list" className="mt-2 leading-8">
                                                <li className="inline">
                                                    <a
                                                        href="#"
                                                        className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                                                    >
                                                        <div className="absolute flex-shrink-0 flex items-center justify-center">
                                                            <span className="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-3.5 text-sm font-medium text-gray-900">Bug</div>
                                                    </a>{' '}
                                                </li>
                                                <li className="inline">
                                                    <a
                                                        href="#"
                                                        className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                                                    >
                                                        <div className="absolute flex-shrink-0 flex items-center justify-center">
                                                            <span className="h-1.5 w-1.5 rounded-full bg-indigo-500" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-3.5 text-sm font-medium text-gray-900">Accessibility</div>
                                                    </a>{' '}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                                <div className="py-3 xl:pt-6 xl:pb-0">
                                    <h2 className="sr-only">Description</h2>
                                    <div className="prose max-w-none">
                                        {report.description}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <TabViewRoutes
                            basePath={`/whistleblow/report/${id}`}
                            routes={[
                                { to: ``, label: 'Aktivitas', icon: { solid: props => <Icon.IconPhospor.Pulse {...props} weight="fill" />, outline: Icon.IconPhospor.Pulse }, element: DetailReportSummarySubRoute },
                                { to: `/file`, label: 'Berkas', icon: { solid: props => <Icon.IconPhospor.Folder {...props} weight="fill" />, outline: Icon.IconPhospor.Folder }, element: DetailReportFileSubRoute },

                            ]}
                        />
                    </div>
                    <aside className="hidden xl:block xl:pl-8">
                        <h2 className="sr-only">Detail</h2>
                        <div className="space-y-5">
                            {report.status !== 'Open' ? null :
                                <div className="flex items-center space-x-2">
                                    <Icon.IconSolid.LockOpenIcon className="h-5 w-5 text-sky-500" aria-hidden="true" />
                                    <span className="text-green-700 text-sm font-medium">Proses</span>
                                </div>
                            }
                            {report.status !== 'Reject' ? null :
                                <div className="flex items-center space-x-2">
                                    <Icon.IconSolid.LockClosedIcon className="h-5 w-5 text-rose-500" aria-hidden="true" />
                                    <span className="text-rose-700 text-sm font-medium">Tolak</span>
                                </div>
                            }
                            {report.status !== 'Finish' ? null :
                                <div className="flex items-center space-x-2">
                                    <Icon.IconSolid.LockClosedIcon className="h-5 w-5 text-lime-500" aria-hidden="true" />
                                    <span className="text-lime-700 text-sm font-medium">Selesai</span>
                                </div>
                            }
                            <div className="flex items-center space-x-2">
                                <Icon.IconSolid.ChatBubbleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className="text-gray-900 text-sm font-medium">{report.commentCounts} komentar</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Icon.IconSolid.CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className="text-gray-900 text-sm font-medium">
                                    Dilaporkan pada <time dateTime={report.created}>{humanizeRelativeFromDate(report.created)}</time><br />
                                    <span className="text-gray-400">{humanizeDateTime(report.created)}</span>
                                </span>
                            </div>
                        </div>
                        <div className="mt-6 border-t border-gray-200 py-6 space-y-8">
                            <div>
                                <h2 className="text-sm font-medium text-gray-500">Pelapor</h2>
                                <ul role="list" className="mt-3 space-y-3">
                                    <li className="flex justify-start">
                                        <a href="#" className="flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                                {report.createdBy.account.avatarURL === null ?
                                                    <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
                                                        <span className="text-xs font-medium leading-none text-white -ml-0.5 -mt-1">
                                                            {toInitial(report.createdBy.account.email)}
                                                        </span>
                                                    </span> :
                                                    <img
                                                        className="h-6 w-6 rounded-full"
                                                        src={report.createdBy.account.avatarURL}
                                                        alt=""
                                                    />
                                                }
                                            </div>
                                            <div className="text-sm font-medium text-gray-900">
                                                {report.createdBy.employee?.name || report.createdBy.account.email}
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="text-sm font-medium text-gray-500">Tags</h2>
                                <ul role="list" className="mt-2 leading-8">
                                    <li className="inline">
                                        <a
                                            href="#"
                                            className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                                        >
                                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                                                <span className="h-1.5 w-1.5 rounded-full bg-rose-500" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3.5 text-sm font-medium text-gray-900">Bug</div>
                                        </a>{' '}
                                    </li>
                                    <li className="inline">
                                        <a
                                            href="#"
                                            className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                                        >
                                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                                                <span className="h-1.5 w-1.5 rounded-full bg-indigo-500" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3.5 text-sm font-medium text-gray-900">Accessibility</div>
                                        </a>{' '}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </main>
    )
}