import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Badge, Button, Flex, ProgressBar, Text } from "@tremor/react";
import { classNames } from "primereact/utils";
import moment from "moment";
import CalenderView from "Common/UI/Block/CalenderView";
import Icon from "Common/UI/Component/Icon";
import { transformResponseToSingle } from "Common/Utility/formatter";
import Loading from "Common/UI/Component/Loading";
import { generateArray } from "Common/Utility/generator";
import { useConfirmDialogStore } from "Common/UI/Block/ConfirmDialog";
import { useCalendarAttendance, useCreateAttendance, useDateAttendance, useDownloadAvailableEmployeeAttendance } from "Core/HumanResource/API/AttendanceAPI";
import { useCreateAttendanceDialogStore } from "Core/HumanResource/UI/Attendance/CreateAttendanceActivityDialog";
import { useCalendarMeeting } from "Core/Appointment/API/MeetingAPI";
import { useListMeetingByDateDialog } from "Core/Appointment/UI/Meeting/ListMeetingByDateDialog";
import { ROLE_EMPLOYEE, useAuthentication } from "Common/Provider/Authentication";
import { humanizePercentage } from "Common/Utility/humanize";
import { colorBasedOnAttendanceRate } from "Core/HumanResource/Utility/Colorize";
import { SearchParams } from "Common/Utility/search-params";
import CardMonthAttendanceRate from "App/human-resource/attendance/calendar/UI/CardMonthAttendanceRate";
import CardMonthHoliday from "App/human-resource/attendance/calendar/UI/CardMonthHoliday";
import { useDetailEmployeeContext } from "../Context";
import Table from "Common/UI/Component/Table";

export default function DetailEmployeeAttendance() {
    const { employee } = useDetailEmployeeContext()
    const confirm = useConfirmDialogStore(state => state.confirm)
    const displayListMeetingByDate = useListMeetingByDateDialog(state => state.display)
    const confirmCreateAttendance = useCreateAttendanceDialogStore(state => state.confirm)
    const { user, token, role } = useAuthentication()
    const [searchParams, setSearchParams] = useSearchParams();
    const period = searchParams.get('period')
    const attendanceDate = searchParams.get('attendance.date')
    const momentPeriod = period ? moment(period, 'YYYY-MM') : moment()
    const startDate = momentPeriod.startOf('month').format('YYYY-MM-DD')
    const endDate = momentPeriod.endOf('month').format('YYYY-MM-DD')


    const calenderAttendance = useCalendarAttendance({ startDate, endDate, employeeID: employee.ID, properties: ['attendance', 'holidays', 'duties', 'isHoliday', 'attendanceRate'] })
    const calenderAttendances = transformResponseToSingle(calenderAttendance.data)

    const dateAttendance = useDateAttendance({ date: searchParams.get('attendance.date'), enabled: !!searchParams.get('attendance.date'), employeeID: employee.ID })

    const selectedDateAttendance = transformResponseToSingle(dateAttendance.data)

    const downloadAvailableEmployeeAttendance = useDownloadAvailableEmployeeAttendance({ start: momentPeriod.startOf('month').format('YYYY-MM'), token: token, employeeID: employee.ID, })
    const availableDownloadEmployeeAttendance = transformResponseToSingle(downloadAvailableEmployeeAttendance.data)


    const calenderMeeting = useCalendarMeeting({ startDate, endDate, employeeID: employee.ID, })
    const calenderMeetings = transformResponseToSingle(calenderMeeting.data)

    const createAttendance = useCreateAttendance({
        onSuccess: () => {
            calenderAttendance.refetch()
        }
    })

    const monthHolidays = calenderAttendance.isLoading ? [] : Object.values(calenderAttendances).map(({ holidays }, index) => holidays).flat()

    console.log('selectedDateAttendance', selectedDateAttendance)
    return (
        <div>
            <div className="m-1.5 md:m-10 gap-8 flex flex-col">
                <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-4 md:col-span-3">
                        <CalenderView
                            title="Kalender Presensi Bulanan"
                            additionalMenu={(
                                <div>
                                    <a href={`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : 'https://keuangan.mitrasdudi.id/api/en'}/service/download/attendance/employee-attendance?token=${token}&start=${period}&requestAs=${role}&employeeID=${employee.ID}`}>
                                        <Button loading={downloadAvailableEmployeeAttendance.isLoading} disabled={!availableDownloadEmployeeAttendance?.available} color="sky" size="xs" variant={"secondary"} a icon={Icon.IconPhospor.FileXls}>
                                            Unduh Log Presensi <span className="font-bold">Bulan {momentPeriod.format('MMM, YYYY')}</span>
                                        </Button>
                                    </a>
                                </div>
                            )}
                            selectableDateOn="date-label"
                            selectedDate={attendanceDate}
                            onSelectedDate={({ date }) => setSearchParams(SearchParams.updates(searchParams, { 'attendance.date': date }))}
                            initialCursorDate={period ? moment(period, 'YYYY-MM-DD') : moment()}
                            onChangeDays={(days) => setSearchParams({ period: moment(days.find(item => item.isCurrentMonth).date, 'YYYY-MM-DD').format('YYYY-MM') })}
                            classItem={day => !calenderAttendance.isLoading && calenderAttendances[day.date]?.isHoliday ? 'bg-rose-100' : null}
                            renderItem={day => (
                                <div className="pt-3" >
                                    {!day.isCurrentMonth ? null :
                                        calenderAttendance.isLoading || calenderMeeting.isLoading ? <Loading.Spinner size="20px" /> :
                                            calenderAttendances === null || (typeof calenderAttendances[day.date] === 'undefined') ? null :
                                                calenderAttendances[day.date].isHoliday ?
                                                    <Fragment>
                                                        {calenderAttendances[day.date]?.holidays.map(holiday => (
                                                            <Badge color="rose" size="xs" icon={Icon.IconPhospor.CalendarX}>
                                                                <span className=" hidden xl:block">{holiday.title}</span>
                                                            </Badge>
                                                        ))}
                                                    </Fragment> :
                                                    <Fragment>
                                                        <div className="flex gap-x-1.5 gap-y-1.5 w-full flex-wrap">
                                                            <Badge color={calenderAttendances[day.date]?.attendance?.clock.in ? 'lime' : 'gray'} size="xs" icon={Icon.IconPhospor.SignIn}>
                                                                <span className="hidden lg:block">Masuk</span>
                                                            </Badge>
                                                            <Badge color={calenderAttendances[day.date]?.attendance?.clock.out ? 'lime' : 'gray'} size="xs" icon={Icon.IconPhospor.SignOut}>
                                                                <span className="hidden lg:block">Keluar</span>
                                                            </Badge>
                                                        </div>
                                                        <div className="gap-2">
                                                            <button onClick={() => confirmCreateAttendance({ date: day.date })}>
                                                                <Badge color={calenderAttendances[day.date]?.attendance?.activities.length ? 'lime' : 'gray'} size="xs" icon={Icon.IconPhospor.Notepad}>
                                                                    <span className="hidden lg:block">Log ({calenderAttendances[day.date]?.attendance?.activities.length || 0}/3)</span>
                                                                </Badge>
                                                            </button>

                                                            {calenderAttendances[day.date]?.duties.length === 0 ? null :
                                                                <Badge color="amber" size="xs" icon={Icon.IconPhospor.AirplaneTakeoff}>
                                                                    <span className=" hidden xl:block">{calenderAttendances[day.date].duties.length} Dinas</span>
                                                                </Badge>
                                                            }
                                                        </div>
                                                        <div className="gap-2">
                                                            {calenderMeetings[day.date]?.meetings.length === 0 ? null :
                                                                <button onClick={() => displayListMeetingByDate({ date: day.date })}>
                                                                    <Badge color="sky" size="xs" icon={Icon.IconPhospor.Users}>
                                                                        <span className=" hidden xl:block">{calenderMeetings[day.date].meetings.length} Pertemuan</span>
                                                                    </Badge>
                                                                </button>
                                                            }
                                                        </div>
                                                    </Fragment>
                                    }
                                </div>
                            )}
                            renderItemMobile={day => {
                                return (
                                    <button onClick={() => confirmCreateAttendance({ date: day.date })}>
                                        {!day.isCurrentMonth || calenderAttendances === null || (typeof calenderAttendances[day.date] === 'undefined') ? null :
                                            calenderAttendance.isLoading || calenderAttendance.isLoading ? <Loading.Spinner size="20px" /> :
                                                calenderAttendances[day.date]?.isHoliday ?
                                                    <Fragment>
                                                        <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                            {calenderAttendances[day.date]?.holidays.length === 0 ? null :
                                                                generateArray(calenderAttendances[day.date]?.holidays.length).map((_, index) =>
                                                                    <div key={index} className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", 'bg-rose-400')} />
                                                                )
                                                            }
                                                        </div>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                            <div className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", `bg-${colorBasedOnAttendanceRate(calenderAttendances[day.date].attendanceRate.percentage)}-400`)} />
                                                        </div>
                                                    </Fragment>
                                        }
                                    </button>
                                )
                            }}

                        />
                    </div>
                    <div className="col-span-4 md:col-span-1 mt-0 md:mt-28 flex gap-3 flex-col">
                        <CardMonthAttendanceRate title="Presensi Bulan sekarang" subtitle={`Rerata Presensi • ${momentPeriod.clone().format('MMM YYYY')}`} period={momentPeriod.clone().format('YYYY-MM')} />
                        <CardMonthAttendanceRate title="Presensi Bulan kemarin" subtitle={`Rerata Presensi • ${momentPeriod.clone().subtract(1, 'month').format('MMM YYYY')}`} period={momentPeriod.clone().subtract(1, 'month').format('YYYY-MM')} />
                        <CardMonthHoliday monthHolidays={monthHolidays} calenderAttendance={calenderAttendance} momentPeriod={momentPeriod} />
                    </div>
                </div>
            </div>


            {selectedDateAttendance && (
                <div>
                    <div>
                        <time dateTime="2022-01" className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">
                            <span className="text-indigo-500">{moment(selectedDateAttendance.date, 'YYYY-MM-DD').lang('id').format('dddd')}</span>, {moment(selectedDateAttendance.date, 'YYYY-MM-DD').lang('id').format('D MMMM YYYY')}
                        </time>
                        <h2 className=" text-gray-500 text-xl -mt-2 font-medium space-x-2 whitespace-nowrap mb-3">
                            Log Harian, Detail Dinas dan Daftar Meeting
                        </h2>
                    </div>


                    <div className="mx-10 mb-3">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5  md:rounded-lg">
                            <Table.Data
                                items={selectedDateAttendance.activities}
                                keySelector={item => item.ID}
                                columns={[
                                    {
                                        header: {
                                            name: 'Sesi',
                                        },
                                        value: {
                                            select: (item) => ({
                                                1: 'Pagi',
                                                2: 'Siang',
                                                3: 'Sore'
                                            }[item.type]),
                                            renderer: Table.Renderer.Simple
                                        },
                                        option: {
                                            primary: true,
                                        }
                                    },

                                    {
                                        header: {
                                            name: 'Kegiatan',
                                        },
                                        value: {
                                            select: (item) => item.activity,
                                            renderer: Table.Renderer.Simple,
                                        },
                                        option: {
                                            showOn: 'lg'
                                        }

                                    },
                                ]}

                                actions={[

                                ]}

                            />

                        </div>
                    </div>
                </div>

            )}

        </div>
    )
}