import { Fragment, useState } from 'react'

import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { transformResponseToSingle } from 'Common/Utility/formatter'
import { humanizeDate, humanizeDateTime } from 'Common/Utility/humanize'
import { ActivityLog } from './ActivityLog'
import { useDetailAccount } from 'Core/Authentication/API/AccountAPI'
import { useAssignAccountSuperAdministratorDialogStore, AssignAccountSuperAdministratorDialog } from 'Core/Authentication/UI/Account/AssignAccountSuperAdministratorDialog'
import { useInvokeAccountSuperAdministratorDialogStore, InvokeAccountSuperAdministratorDialog } from 'Core/Authentication/UI/Account/InvokeAccountSuperAdministratorDialog'
import { useInvokeAccountEmployeeDialogStore, InvokeAccountEmployeeDialog } from 'Core/Authentication/UI/Account/InvokeAccountEmployeeDialog'
import { useAssignAccountEmployeeDialogStore, AssignAccountEmployeeDialog } from 'Core/Authentication/UI/Account/AssignAccountEmployeeDialog'
import { useInvokeAccountWhistleblowOperatorDialogStore, InvokeAccountWhistleblowOperatorDialog } from 'Core/Authentication/UI/Account/InvokeAccountWhistleblowOperatorDialog'
import { useAssignAccountWhistleblowOperatorDialogStore, AssignAccountWhistleblowOperatorDialog } from 'Core/Authentication/UI/Account/AssignAccountWhistleblowOperatorDialog'

export default function DetailAccountRoute() {
    const { id } = useParams()
    const [actionState, setActionState] = useState(null)
    const detailAccount = useDetailAccount(id, { expands: ['role'] });
    const account = transformResponseToSingle(detailAccount.data)

    const confirmAssignSuperAdministrator = useAssignAccountSuperAdministratorDialogStore(state => state.confirm)
    const confirmInvokeSuperAdministrator = useInvokeAccountSuperAdministratorDialogStore(state => state.confirm)


    const confirmAssignEmployee = useAssignAccountEmployeeDialogStore(state => state.confirm)
    const confirmInvokeEmployee = useInvokeAccountEmployeeDialogStore(state => state.confirm)

    const confirmAssignWhistleblowOperator = useAssignAccountWhistleblowOperatorDialogStore(state => state.confirm)
    const confirmInvokeWhistleblowOperator = useInvokeAccountWhistleblowOperatorDialogStore(state => state.confirm)

    if (detailAccount.isLoading) {
        return null
    }

    return (
        <Fragment className="">
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
                <main className="py-10 w-full">
                    {/* Page header */}
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                        <div className="flex items-center space-x-5">
                            <div className="flex-shrink-0">
                                <div className="relative">
                                    {account.avatar ?
                                        <img
                                            className="h-16 w-16 rounded-full"
                                            src={account.avatar.fullURL}
                                            alt=""
                                        /> :
                                        <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                            <span className="font-medium leading-none text-white">
                                                {account.email.split(" ").map((part) => `${part[0]}`.toLocaleUpperCase()).join("")}
                                            </span>
                                        </span>
                                    }
                                </div>
                            </div>
                            <div>
                                <h1 className="text-lg md:text-2xl font-bold text-gray-900">{account.email}</h1>
                                <p className="text-sm font-medium text-gray-500">
                                    Created {' '}
                                    on <time dateTime={account.created}>{humanizeDate(account.created)}</time>
                                </p>
                            </div>

                        </div>
                        <div className='flex flex-wrap gap-2 mt-4 md:mt-0'>
                            {account.role.superAdministrator && (
                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                            className={classNames('bg-indigo-500', 'h-1.5 w-1.5 rounded-full')}
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900 hidden md:block">
                                        Super Administrator
                                    </span>
                                    <span className='ml-3.5 block md:hidden'>SA</span>
                                </button>
                            )}
                            {account.role.administrator && (
                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                            className={classNames('bg-red-500', 'h-1.5 w-1.5 rounded-full')}
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900 hidden md:block">
                                        Super Administrator
                                    </span>
                                    <span className='ml-3.5 block md:hidden'>Admin</span>
                                </button>
                            )}
                            {account.role.whistleblowOperator && (
                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                            className={classNames('bg-sky-500', 'h-1.5 w-1.5 rounded-full')}
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900">Operator WBS</span>
                                </button>
                            )}
                            {account.role.employee && (
                                <button className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                            className={classNames('bg-green-500', 'h-1.5 w-1.5 rounded-full')}
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900">Karyawan</span>
                                </button>
                            )}
                        </div>
                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                            <button
                                type="button"
                                onClick={() => setActionState('update-password')}
                                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                            >
                                Update Password
                            </button>
                        </div>
                    </div>

                    <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                            {/* Description list*/}
                            {account.role.whistleblowOperator && (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Operator WBS
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasi terkait hak akses ini</p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Diberikan Hak Akses pada</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <div className=''>{humanizeDateTime(account.role.whistleblowOperator.created)}</div>
                                                        <div className='font-light text-xs'>{account.role.whistleblowOperator.created}</div>
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Tipe Akses</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <div className=''>{account.role.whistleblowOperator.type}</div>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => confirmInvokeWhistleblowOperator({ ID: account.ID })} className="w-0 flex-1 flex">
                                                <a
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Copot</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                            {!account.role.whistleblowOperator && (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg opacity-30 hover:opacity-100">

                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Operator WBS
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Super administrator access information.</p>
                                        </div>

                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => confirmAssignWhistleblowOperator({ ID: account.ID })} className="w-0 flex-1 flex">
                                                <a
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Kasih Akses</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                            {account.role.superAdministrator && (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Super Administrator
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Super administrator access information.</p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Diberikan Hak Akses pada</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <div className=''>{humanizeDateTime(account.role.superAdministrator.created)}</div>
                                                        <div className='font-light text-xs'>{account.role.superAdministrator.created}</div>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => confirmInvokeSuperAdministrator({ ID: account.ID })} className="w-0 flex-1 flex">
                                                <a
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Copot Hak Akses</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                            {!account.role.superAdministrator && (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg opacity-30 hover:opacity-100">

                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Super Administrator
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Super administrator access information.</p>
                                        </div>

                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => confirmAssignSuperAdministrator({ ID: account.ID })} className="w-0 flex-1 flex">
                                                <a
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Kasih Akses</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                            {account.role.employee === null ? null : (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Employee
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Employee access information.</p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Full Name
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.name}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Phone
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.phone ?? '-'}
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Tanggal Lahir
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.bornDate ?? '-'}
                                                    </dd>
                                                </div>


                                                <div className="sm:col-span-2 col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Address
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {account.role.employee.roleEntity.address ?? '-'}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>

                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => confirmInvokeEmployee({ ID: account.ID })} className="w-0 flex-1 flex">
                                                <div
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Copot</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}

                            {!account.role.employee && (
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg opacity-30 hover:opacity-100">

                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                                Employee
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Employee access information.</p>
                                        </div>

                                        <div className="-mt-px flex divide-x divide-gray-200 border-t">
                                            <button onClick={() => confirmAssignEmployee({ ID: account.ID })} className="w-0 flex-1 flex">
                                                <a
                                                    className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                                >
                                                    <span className="ml-3">Kasih Hak Akses</span>
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>

                        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                            <ActivityLog />
                        </section>
                    </div>
                </main>
            </div>

            <AssignAccountSuperAdministratorDialog
                onConfirm={() => {
                    detailAccount.refetch()
                }}
            />

            <InvokeAccountSuperAdministratorDialog
                onConfirm={() => {
                    detailAccount.refetch()
                }}
            />

            <AssignAccountEmployeeDialog
                onConfirm={() => {
                    detailAccount.refetch()
                }}
            />

            <InvokeAccountEmployeeDialog
                onConfirm={() => {
                    detailAccount.refetch()
                }}
            />

            <AssignAccountWhistleblowOperatorDialog
                onConfirm={() => {
                    detailAccount.refetch()
                }}
            />

            <InvokeAccountWhistleblowOperatorDialog
                onConfirm={() => {
                    detailAccount.refetch()
                }}
            />

        </Fragment>
    )
}
