import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import ToggleIcon from "./ToggleIconComponent";
import classNames from "classnames";
import Icon from "Common/UI/Component/Icon";

export default function NavLinkComponent({ end = false, className, to, icons = [], name, subtitle }) {
    return (
        <NavLink end={end} to={to} className={({ isActive }) => classNames(
            isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
            className
        )}>
            {
                ({ isActive }) => (
                    <Fragment>
                        <ToggleIcon
                            activeIcon={icons.length >= 2 ? icons[1] : Icon.IconSolid.HomeIcon}
                            inactiveIcon={icons.length >= 1 ? icons[0] : Icon.IconOutline.HomeIcon}
                            isActive={isActive}
                            className={classNames(
                                isActive ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                        />
                        <div className="flex flex-col">
                            <span className='flex items-center justify-start'>
                                {name}
                            </span>
                            {!subtitle ? null :
                                <span className='flex items-center justify-center text-sm text-gray-400'>
                                    {subtitle}
                                </span>
                            }
                        </div>
                    </Fragment>
                )
            }
        </NavLink>
    )
}