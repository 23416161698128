
import CalendarDates from "calendar-dates";
import moment from "moment";

export const calendarDates = new CalendarDates();

export async function generateCalendarDates(date) {
    return await calendarDates.getDates(date)
}

/**
 * Thanks to @kottenator
 * @see https://gist.github.com/kottenator/9d936eb3e4e3c3e02598?permalink_comment_id=3413141#gistcomment-3413141
 * @param int current 
 * @param int total 
 * @returns 
 */
export function generatePagination(current, total) {
    const center = [current - 2, current - 1, current, current + 1, current + 2],
        filteredCenter = center.filter((p) => p > 1 && p < total),
        includeThreeLeft = current === 5,
        includeThreeRight = current === total - 4,
        includeLeftDots = current > 5,
        includeRightDots = current < total - 4;

    if (includeThreeLeft) filteredCenter.unshift(2)
    if (includeThreeRight) filteredCenter.push(total - 1)

    if (includeLeftDots) filteredCenter.unshift('...');
    if (includeRightDots) filteredCenter.push('...');

    const end = total > 1 ? [total] : []
    return [1, ...filteredCenter, ...end]
}

export function generateArray(size = 3, fill = null) {
    return Array.apply(fill, Array(size))
}

export function generatePeriod(size = 12 * 3) {
    return generateArray(size).map((_, index) => ({ period: moment().subtract(size - index-1, 'month').format('YYYY-MM') }))
}