import { requestDelete, requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useBrowseCompanyAgenda({ sort = 'starDate', order = 'asc', expands = [], pageSize = 20, currentPage = 1, keyword, onSuccess, startDate, endDate, }) {
    const path = `/resource/attendance/company-agenda`
    const data = useQuery({
        queryKey: [path, sort, order, pageSize, keyword, currentPage, startDate, endDate],
        queryFn: () => requestGet(path, { sort, order, keyword, pageSize, currentPage: currentPage || 1, expand: expands.join(','), startDate, endDate }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useCreateCompanyAgenda({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ date, title }) => {
            const path = `/resource/attendance/company-agenda`
            return requestPost(path, {
                date, title,
            })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useDeleteCompanyAgenda({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, password }) => {
            const path = `/resource/attendance/company-agenda/${ID}`
            return requestDelete(path, { password })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}
