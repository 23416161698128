import Button from "Common/UI/Component/Button";
import Icon from "Common/UI/Component/Icon";
import Input from "Common/UI/Component/Input";
import { humanizeRelativeFromDate } from "Common/Utility/humanize";
import { Fragment, useState } from "react";
import { useDetailReportContext } from "./DetailReportContext";
import { useAuthentication } from "Common/Provider/Authentication";

export default function DetailReportSummarySubRoute() {
    const { id, infiniteScrollActivityLog, activityLogs, isActivityLogHaveMore, createActivityLog } = useDetailReportContext()
    const { user } = useAuthentication()
    const [commentContent, setCommentContent] = useState('')

    const submitComment = (e) => {
        e.preventDefault()
        createActivityLog
            .mutateAsync({ action: 'Comment', reportID: id, content: commentContent })
            .then(() => {
                setCommentContent('')
            })
    }

    return (
        <section className="mt-2 xl:mt-4">
            <div className="pt-6">
                {/* Activity feed*/}
                <div className="flow-root">
                    <ul role="list" className="-mb-8">
                        {activityLogs.map((activityLog, activityLogIndex) => (
                            <li key={activityLog.ID}>
                                <div className="relative pb-8">
                                    {activityLogIndex !== activityLogs.length - 1 ? (
                                        <span
                                            className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                            aria-hidden="true"
                                        />
                                    ) : null}
                                    <div className="relative flex items-start space-x-3">
                                        {activityLog.action !== 'Comment' ? null :
                                            <Fragment>
                                                <div className="relative">
                                                    <img
                                                        className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                                        src={activityLog.data.author.account.avatarURL}
                                                        alt=""
                                                    />

                                                    <span className="absolute -bottom-2 -right-2 bg-white rounded-full p-1 shadow-lg">
                                                        <Icon.IconSolid.ChatBubbleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </span>
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <div>
                                                        <div className="text-sm">
                                                            <a className="font-medium text-gray-900">
                                                                {activityLog.data.author.employee?.name || activityLog.data.author.account.email}
                                                            </a>
                                                        </div>
                                                        <p className="mt-0.5 text-sm text-gray-500">Berkomentar pada {humanizeRelativeFromDate(activityLog.created)}</p>
                                                    </div>
                                                    <div className="mt-2 text-sm text-gray-700">
                                                        <p>{activityLog.data.content}</p>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }
                                        {activityLog.action !== 'Status Change' ? null :
                                            <Fragment>
                                                <div>
                                                    <div className="relative px-1">
                                                        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                            {activityLog.data.statusTo !== 'Open' ? null :
                                                                <Icon.IconSolid.LockOpenIcon
                                                                    className="h-5 w-5 text-sky-500"
                                                                    aria-hidden="true"
                                                                />
                                                            }
                                                            {activityLog.data.statusTo !== 'Finish' ? null :
                                                                <Icon.IconSolid.LockClosedIcon
                                                                    className="h-5 w-5 text-lime-500"
                                                                    aria-hidden="true"
                                                                />
                                                            }
                                                            {activityLog.data.statusTo !== 'Reject' ? null :
                                                                <Icon.IconSolid.LockClosedIcon
                                                                    className="h-5 w-5 text-rose-500"
                                                                    aria-hidden="true"
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="min-w-0 flex-1 py-1.5">
                                                    <div className="text-sm text-gray-500">
                                                        <span className="font-medium text-gray-900">
                                                            {activityLog.data.author.employee?.name || activityLog.data.author.account.email}
                                                        </span>{' '}
                                                        mengubah status{' '} dari {' '}
                                                        <span className="font-medium text-gray-900">
                                                            {activityLog.data.statusFrom}
                                                        </span>
                                                        {' '} ke {' '}
                                                        <span className="font-medium text-gray-900">
                                                            {activityLog.data.statusTo}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }
                                        {activityLog.action === 'Comment' ? (
                                            <>

                                            </>
                                        ) : activityLog.action === 'assignment' ? (
                                            <>
                                                {/* <div>
                                                        <div className="relative px-1">
                                                            <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                <Icon.IconSolid.UserCircleIcon
                                                                    className="h-5 w-5 text-gray-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="min-w-0 flex-1 py-1.5">
                                                        <div className="text-sm text-gray-500">
                                                            <a href={item.person.href} className="font-medium text-gray-900">
                                                                {item.person.name}
                                                            </a>{' '}
                                                            assigned{' '}
                                                            <a href={item.assigned.href} className="font-medium text-gray-900">
                                                                {item.assigned.name}
                                                            </a>{' '}
                                                            <span className="whitespace-nowrap">{item.date}</span>
                                                        </div>
                                                    </div> */}
                                            </>
                                        ) : (
                                            <>
                                                {/* <div>
                                                        <div className="relative px-1">
                                                            <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                <Icon.IconSolid.TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="min-w-0 flex-1 py-0">
                                                        <div className="text-sm leading-8 text-gray-500">
                                                            <span className="mr-0.5">
                                                                <a href={item.person.href} className="font-medium text-gray-900">
                                                                    {item.person.name}
                                                                </a>{' '}
                                                                added tags
                                                            </span>{' '}
                                                            <span className="mr-0.5">
                                                                {item.tags.map((tag) => (
                                                                    <Fragment key={tag.name}>
                                                                        <a
                                                                            href={tag.href}
                                                                            className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                                                                        >
                                                                            <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                                                <span
                                                                                    className={classNames(tag.color, 'h-1.5 w-1.5 rounded-full')}
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                            <span className="ml-3.5 font-medium text-gray-900">
                                                                                {tag.name}
                                                                            </span>
                                                                        </a>{' '}
                                                                    </Fragment>
                                                                ))}
                                                            </span>
                                                            <span className="whitespace-nowrap">{item.date}</span>
                                                        </div>
                                                    </div> */}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                        <li>

                            <Button.LoadableButton
                                disabled={!isActivityLogHaveMore}
                                loading={infiniteScrollActivityLog.isLoading}
                                onClick={() => infiniteScrollActivityLog.fetchNextPage()}
                                color={isActivityLogHaveMore ? 'indigo' : 'gray'}
                                variant={isActivityLogHaveMore ? 'primary' : 'secondary'}
                            >
                                {isActivityLogHaveMore ?
                                    'Muat Selanjutnya' :
                                    'Tidak ada Lagi'
                                }

                            </Button.LoadableButton>
                        </li>
                    </ul>
                </div>
                <div className="mt-16">
                    <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                <img
                                    className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                    src={user.avatarURL}
                                    alt=""
                                />
                                <span className="absolute -bottom-2 -right-2 bg-white rounded-full p-1 shadow-lg">
                                    <Icon.IconSolid.ChatBubbleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </div>
                        </div>
                        <div className="min-w-0 flex-1">
                            <form onSubmit={submitComment}>
                                <div>
                                    <label htmlFor="comment" className="sr-only">
                                        Comment
                                    </label>
                                    <Input.InputTextarea
                                        value={commentContent} onChange={(e) => setCommentContent(e.target.value)}
                                        rows={5} cols={30}
                                        className="w-full"
                                    />
                                </div>
                                <div className="mt-6 flex items-center justify-end space-x-4">
                                    <Button.LoadableButton icon={Icon.IconPhospor.PaperPlaneTilt} disabled={commentContent?.trim() === '' || createActivityLog.isLoading} loading={createActivityLog.isLoading} loadingText="Menyimpan ..." color="black">
                                        Kirim Komentar
                                    </Button.LoadableButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}