import { Fragment } from "react";
import * as IconOutline from '@heroicons/react/24/outline'
import * as IconSolid from '@heroicons/react/24/solid'
import NavLinkComponent from "./NavLinkComponent";
import SidebarMenuGroup from "./NavLinkBlock";
import Icon from "Common/UI/Component/Icon";
import { ROLE_EMPLOYEE, ROLE_SUPER_ADMINISTRATOR, useAuthentication } from "Common/Provider/Authentication";

export default function NavigationPanel() {
    const { user, role } = useAuthentication()

    return (
        <Fragment>
            <NavLinkComponent to='/' icons={[IconOutline.HomeIcon, IconSolid.HomeIcon]} name="Dashboard" />
            {role === ROLE_EMPLOYEE &&
                <NavLinkComponent to={`/human-resource/employee/${user.role.employee.roleEntity.ID}`} icons={[IconOutline.IdentificationIcon, IconSolid.IdentificationIcon]} name="Profil Pribadi" />
            }


            {role === ROLE_SUPER_ADMINISTRATOR || (role === ROLE_EMPLOYEE && user.role.employee && (user.role.employee.attendanceModule.mode !== 0 || user.role.employee.humanResourceModule.mode !== 0)) ? (
                <Fragment>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-2 bg-white text-sm text-gray-500">HRD</span>
                        </div>
                    </div>


                    {role === ROLE_SUPER_ADMINISTRATOR || user.role.employee.humanResourceModule.mode !== 0 ? (
                        <SidebarMenuGroup to='/human-resource/employee/employee' icons={[Icon.IconPhospor.UsersFour, props => <Icon.IconPhospor.UsersFour weight="fill" {...props} />]} name="Karyawan"
                            items={[
                                { to: '/human-resource/employee/employee?isInActive=false', name: 'Daftar Karyawan', icons: [Icon.IconPhospor.ListDashes, props => <Icon.IconPhospor.ListDashes weight="fill" {...props} />] },
                            ]}
                        />
                    ) : null}


                    {role === ROLE_SUPER_ADMINISTRATOR ?
                        <SidebarMenuGroup to='human-resource/attendance' icons={[Icon.IconPhospor.Calendar, props => <Icon.IconPhospor.Calendar weight="fill" {...props} />]} name="Presensi"
                            items={[
                                { to: 'human-resource/attendance', name: 'Kalender', icons: [Icon.IconPhospor.Calendar, props => <Icon.IconPhospor.Calendar weight="fill" {...props} />] },
                                { to: '/human-resource/attendance/holiday-date', name: 'Libur', icons: [Icon.IconPhospor.CalendarX, props => <Icon.IconPhospor.CalendarX weight="fill" {...props} />] },
                            ]}
                        /> :
                        user.role.employee.attendanceModule.mode !== 0 && (
                            user.role.employee.attendanceModule.mode > 4 ?
                                <SidebarMenuGroup to='human-resource/attendance' icons={[Icon.IconPhospor.Calendar, props => <Icon.IconPhospor.Calendar weight="fill" {...props} />]} name="Presensi"
                                    items={[
                                        { to: 'human-resource/attendance', name: 'Kalender', icons: [Icon.IconPhospor.Calendar, props => <Icon.IconPhospor.Calendar weight="fill" {...props} />] },
                                        ...(
                                            user.role.employee.attendanceModule.mode >= 4 ?
                                                [{ to: '/human-resource/attendance/holiday-date', name: 'Libur', icons: [Icon.IconPhospor.CalendarX, props => <Icon.IconPhospor.CalendarX weight="fill" {...props} />] }] :
                                                []
                                        ),
                                        ...(
                                            user.role.employee.attendanceModule.mode >= 4 ?
                                                [{ to: '/human-resource/attendance/agenda', name: 'Agenda', icons: [Icon.IconPhospor.CalendarCheck, props => <Icon.IconPhospor.CalendarCheck weight="fill" {...props} />] }] :
                                                []
                                        ),
                                    ]}
                                /> :
                                <NavLinkComponent to='/human-resource/attendance' icons={[Icon.IconPhospor.Calendar, props => <Icon.IconPhospor.Calendar weight="fill" {...props} />]} name="Kalender" />
                        )


                    }
                </Fragment>

            ) : null}



            {!(role === ROLE_SUPER_ADMINISTRATOR) ? null :
                <Fragment>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-2 bg-white text-sm text-gray-500">WBS</span>
                        </div>
                    </div>

                    <SidebarMenuGroup to='/whistleblow' icons={[Icon.IconPhospor.Megaphone, props => <Icon.IconPhospor.Megaphone weight="fill" {...props} />]} name="Laporan"
                        items={[
                            { to: '/whistleblow/report', name: 'Daftar Laporan', icons: [IconOutline.RectangleStackIcon, IconSolid.RectangleStackIcon] },
                        ]}
                    />
                </Fragment>
            }

            {!(role === ROLE_SUPER_ADMINISTRATOR) ? null :
                <Fragment>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-2 bg-white text-sm text-gray-500">Formulir</span>
                        </div>
                    </div>

                    <SidebarMenuGroup to='/form' icons={[Icon.IconPhospor.Table, props => <Icon.IconPhospor.Table weight="fill" {...props} />]} name="Formulir"
                        items={[
                            { to: '/form/form?deleted=false&start=0,1', name: 'Daftar Form', icons: [IconOutline.RectangleStackIcon, IconSolid.RectangleStackIcon] },
                        ]}
                    />
                </Fragment>
            }


            {!(role === ROLE_SUPER_ADMINISTRATOR) ? null :
                <Fragment>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-2 bg-white text-sm text-gray-500">Konsol</span>
                        </div>
                    </div>

                    <NavLinkComponent to='/authentication/account' icons={[IconOutline.UserGroupIcon, IconSolid.UserGroupIcon]} name="Akses" />
                    <SidebarMenuGroup to='/content' icons={[Icon.IconPhospor.Layout, props => <Icon.IconPhospor.Layout weight="fill" {...props} />]} name="Konten"
                        items={[
                            { to: '/content/web', name: 'Web Portal', subtitle: 'https://mitrasdudi.id', icons: [Icon.IconPhospor.Desktop, props => <Icon.IconPhospor.Desktop weight="fill" {...props} />] },
                        ]}
                    />
                </Fragment>
            }
        </Fragment>
    )
}