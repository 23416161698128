import { Badge } from "@tremor/react"
import Icon from "Common/UI/Component/Icon";

const Transaction = ({value}) => (
    <Badge
        color={(
            {
                'Pending': 'gray',
                'Confirmed': 'sky',
                'Cancel': 'rose',
                'Processing': 'yellow',
                'Finished': 'lime'
            }[value] || 'gray'
        )}
        icon={(
            {
                'Pending': Icon.IconPhospor.Seal,
                'Confirmed': Icon.IconPhospor.SealCheck,
                'Cancel': Icon.IconPhospor.XCircle,
                'Processing': Icon.IconPhospor.PersonSimpleRun,
                'Finished': Icon.IconPhospor.FlagBanner
            }[value] || Icon.IconPhospor.SealQuestion
        )}
    >
        {value}
    </Badge>
)

export default { Transaction }