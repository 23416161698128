import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import Icon from 'Common/UI/Component/Icon'
import Input from 'Common/UI/Component/Input'
import { transformResponseToSingle } from 'Common/Utility/formatter'
import Button from 'Common/UI/Component/Button'
import { BaseDialog } from 'Common/UI/Block/Dialog'
import { useAssignAccountEmployee } from 'Core/Authentication/API/AccountAPI'
import { Dialog } from '@headlessui/react'
import EmployeeSelect from 'Core/HumanResource/UI/Employee/EmployeeSelect'

const initialStore = {
    show: false,
    ID: '',
    password: '',
    selectedEmployee: null,
}

export const useAssignAccountEmployeeDialogStore = create((set) => ({
    ...initialStore,

    setShow: (value) => set(() => ({ show: value })),
    confirm: ({ ID, selectedEmployee }) => {
        let update = { show: true, ID }
        if (selectedEmployee) {
            update['selectedEmployee'] = selectedEmployee
        }
        return set(() => update)
    },

    setPassword: (value) => set(() => ({ password: value })),
    setSelectedEmployee: (value) => set(() => ({ selectedEmployee: value })),
    reset: () => set(() => initialStore)
}))

export function AssignAccountEmployeeDialog({ onConfirm = () => null }) {
    const show = useAssignAccountEmployeeDialogStore(state => state.show)
    const setShow = useAssignAccountEmployeeDialogStore(state => state.setShow)

    const { ID, password, selectedEmployee } = useAssignAccountEmployeeDialogStore(state => ({ ID: state.ID, password: state.password, selectedEmployee: state.selectedEmployee }), shallow)
    const { setPassword, setSelectedEmployee } = useAssignAccountEmployeeDialogStore(state => ({ setPassword: state.setPassword, setSelectedEmployee: state.setSelectedEmployee }))
    const reset = useAssignAccountEmployeeDialogStore(state => state.reset)

    const assignAccountEmployee = useAssignAccountEmployee({
        onSuccess: (response) => {
            onConfirm(transformResponseToSingle(response))
            reset()
        }
    })


    return (
        <BaseDialog show={show} onClose={() => setShow(false)} >
            {({ cancelButtonRef }) => (
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">

                    <div>
                        <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-lime-100`}>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <Icon.IconPhospor.UserPlus className="h-6 w-6 text-green-600" aria-hidden="true" />
                            </div>
                        </div>


                        <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                Tambah Akses/Akun
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Silahkan lengkapi isian dibawah ini
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='mt-7 grid grid-cols-1 gap-8 md:gap-3'>
                        <span className="p-float-label">
                            <Input.InputSecure value={password} onChange={(e) => setPassword(e.target.value)} rows={5} cols={30} className='w-full' />
                            <label htmlFor="morningActivity">Kata Sandi</label>
                        </span>
                    </div>


                    <div className='mx-6 my-10'>
                        <EmployeeSelect
                            value={selectedEmployee}
                            onChange={setSelectedEmployee}
                        />
                    </div>

                    <div className="flex justify-end gap-1.5 mt-4">
                        <Button.LoadableButton
                            variant='secondary'
                            type="button"
                            onClick={() => setShow(false)}
                            ref={cancelButtonRef}
                        >
                            Batal
                        </Button.LoadableButton>
                        <Button.LoadableButton
                            type='submit'
                            loading={assignAccountEmployee.isLoading}
                            loadingText='Memproses..'
                            onClick={() => {
                                assignAccountEmployee.mutate({ ID, password, employeeID: selectedEmployee?.ID })
                            }}
                        >
                            Simpan
                        </Button.LoadableButton>
                    </div>
                </div>
            )}
        </BaseDialog>
    )
}