import { Badge, Card, Flex, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Text, Title } from "@tremor/react";
import { Pagination } from "Common/UI/Block/Pagination";
import Button from "Common/UI/Component/Button";
import Icon from "Common/UI/Component/Icon";
import { objectFilterNull, transformResponseToList, transformResponseToPagination } from "Common/Utility/formatter";
import { humanizeDate, humanizeDateTime, humanizeRelativeFromDate, humanizeTime } from "Common/Utility/humanize";
import { useBrowseReport } from "Core/Whistleblow/API/ReportAPI";
import { Link, useSearchParams } from "react-router-dom";
import { FileIcon, defaultStyles } from "react-file-icon"
import BrowseDataLayout from "Common/UI/Layout/BrowseData/BrowsePaginateDataLayout";

export default function BrowseReportRoute() {
    const [searchParams, setSearchParams] = useSearchParams()
    const sort = searchParams.get('sort');
    const order = searchParams.get('order');
    const status = searchParams.get('status');

    const browseReport = useBrowseReport({
        sort: sort || 'ID',
        order: order || 'desc',
        status,
        pageSize: searchParams.get('show') || 20,
        currentPage: searchParams.get('page') || 1,
        expands: ['files']
    })
    const reports = transformResponseToList(browseReport.data)
    const pagination = transformResponseToPagination(browseReport.data)

    return (
        <BrowseDataLayout
            title="Sistem Whistleblow"
            subtitle={<span>Manajemen data Form yang akan dirilis ke <a className="text-indigo-600 font-extrabold" href="https://wbs.mitrasdudi.id">https://wbs.mitrasdudi.id</a></span>}
            loading={browseReport.isLoading}
            pagination={pagination}
            filterOptions={[
                {
                    name: 'Status', multiple: false,
                    options: [
                        { name: 'Semua', parameters: { status: null } },
                        { name: 'Tolak', parameters: { status: 'Reject' } },
                        { name: 'Proses', parameters: { status: 'Open' } },
                        { name: 'Tidak Terhapus', parameters: { status: 'Finish' } },
                    ]
                },
            ]}
            sortOptions={[
                { name: 'Data Terbaru', parameters: { sort: 'ID', order: 'desc' } },
                { name: 'Data Terlama', parameters: { sort: 'ID', order: 'asc' } },
            ]}
            sidebar={(
                <div>
                </div>
            )}
        >
            <div className="flex justify-center mb-10">
                <div className="mx-4 sm:w-full">
                    <Card className="h-full">
                        <Flex justifyContent="start" className="space-x-2">
                            <Title>Laporan Tahun Ini</Title>
                            <Badge color="gray">{pagination.itemCount}</Badge>
                        </Flex>
                        <Text className="mt-2">
                            Sampai detik ini
                        </Text>

                        <Table className="mt-6">
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell>ID</TableHeaderCell>
                                    <TableHeaderCell>Waktu Lapor</TableHeaderCell>
                                    <TableHeaderCell>Nama yang Dilapor</TableHeaderCell>
                                    <TableHeaderCell>Laporan</TableHeaderCell>
                                    <TableHeaderCell>Lokasi</TableHeaderCell>
                                    <TableHeaderCell>Waktu Kejadian</TableHeaderCell>
                                    <TableHeaderCell>Status</TableHeaderCell>
                                    <TableHeaderCell>Berkas</TableHeaderCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {browseReport.isLoading ? null :
                                    reports.map((report) => (
                                        <TableRow key={report.ID}>
                                            <TableCell>{report.ID}</TableCell>
                                            <TableCell className="text-right">
                                                <div>
                                                    <div>
                                                        {humanizeDateTime(report.created)}
                                                    </div>
                                                    <div>
                                                        <Badge size="xs" color="gray">{humanizeRelativeFromDate(report.created)}</Badge>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>{report.personName}</TableCell>
                                            <TableCell>{report.title}</TableCell>
                                            <TableCell>
                                                {report.location}
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div>
                                                    <div>
                                                        {humanizeDate(report.date)}
                                                    </div>
                                                    <div>
                                                        <Badge size="xs" color="gray">{humanizeRelativeFromDate(`${report.date} ${report.time}`)}</Badge>
                                                        {' '}{humanizeTime(report.time)}
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {report.status !== 'Open' ? null :
                                                    <div className="flex items-center space-x-2">
                                                        <Icon.IconSolid.LockOpenIcon className="h-5 w-5 text-sky-500" aria-hidden="true" />
                                                        <span className="text-green-700 text-sm font-medium">Proses</span>
                                                    </div>
                                                }
                                                {report.status !== 'Reject' ? null :
                                                    <div className="flex items-center space-x-2">
                                                        <Icon.IconSolid.LockClosedIcon className="h-5 w-5 text-rose-500" aria-hidden="true" />
                                                        <span className="text-rose-700 text-sm font-medium">Tolak</span>
                                                    </div>
                                                }
                                                {report.status !== 'Finish' ? null :
                                                    <div className="flex items-center space-x-2">
                                                        <Icon.IconSolid.LockClosedIcon className="h-5 w-5 text-lime-500" aria-hidden="true" />
                                                        <span className="text-lime-700 text-sm font-medium">Selesai</span>
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div className="flex -space-x-1 relative z-0 overflow-hidden">
                                                    {report.files.slice(0, 4).map(file => (
                                                        <div className="relative z-30 inline-block h-10 w-10 rounded-full ring-2 ring-white bg-gray-200 text-center p-3">
                                                            <FileIcon
                                                                extension={file.type.extension}
                                                                {...defaultStyles[file.type.extension]}
                                                            />
                                                        </div>
                                                    ))}
                                                    {report.files.length <= 4 ? null :
                                                        <div className="relative z-30 inline-block h-10 w-10 rounded-full ring-2 ring-white bg-gray-200 text-center p-3">
                                                            {report.files.length - 4}+
                                                        </div>
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={`/whistleblow/report/${report.ID}`}>
                                                    <Button.LoadableButton icon={Icon.IconPhospor.Eye} size="xs" variant="secondary" color="gray">
                                                        Lihat Laporan
                                                    </Button.LoadableButton>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Card>


                </div>
            </div>
        </BrowseDataLayout>

    )
}