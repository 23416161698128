import { Dialog } from '@headlessui/react'
import { Fragment } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { humanizeRelativeFromDate } from 'Common/Utility/humanize'
import Icon from 'Common/UI/Component/Icon'

export default function FileViewerImage({ file, onClose }) {
    return (
        <Fragment>
            <div className="flex-1">
                {/* Header */}
                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">{file.name}</Dialog.Title>
                            <p className="text-sm font-medium text-gray-500">
                                {file.timestamp.modifiedReadable}
                            </p>
                            <span className='text-gray-400'>{humanizeRelativeFromDate(file.timestamp.modifiedReadable)}</span>
                        </div>
                        <div className="flex h-7 items-center">
                            <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => onClose()}
                            >
                                <span className="sr-only">Close panel</span>
                                <Icon.IconOutline.XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>

                {/* Divider container */}
                <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                    {/* Project name */}
                    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                            <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                                {' '}
                                Ukuran
                            </label>
                        </div>
                        <div className="sm:col-span-2">

                            <div className="avatar placeholder">
                                <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                                    <span className="text-sm text-center">
                                        {file.sizeReadable}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Project description */}
                    <div className="flex mx-3">
                        <PhotoProvider>
                            <PhotoView src={`${file.URL}?t=${+new Date()}`}>
                                <img src={`${file.URL}?t=${+new Date()}`} className="w-full shadow-lg rounded-md cursor-pointer" />
                            </PhotoView>
                        </PhotoProvider>
                    </div>


                </div>
            </div>

            {/* Action buttons */}
            <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                <div className="flex justify-end space-x-3">
                    <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => onClose()}
                    >
                        Tutup
                    </button>
                </div>
            </div>
        </Fragment>
    )
}