import { AreaChart, Card, Metric, Text } from "@tremor/react";
import { attendanceReports } from "./dummy/report";

export default function AttendanceReportCardComponent() {
    return (
        <Card className="mx-auto">
            <Text>Intensitas Clock IN</Text>
            <Metric>93%</Metric>
            <AreaChart
                className="mt-8 h-44"
                data={attendanceReports}
                categories={["Check In", 'Check Out']}
                index="Month"
                colors={["amber", "lime"]}
                showYAxis={false}
                showLegend={false}
            />
        </Card>
    )
}