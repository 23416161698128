import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import Icon from 'Common/UI/Component/Icon'
import Input from 'Common/UI/Component/Input'
import { transformResponseError, transformResponseToSingle } from 'Common/Utility/formatter'
import Button from 'Common/UI/Component/Button'
import { BaseDialog } from 'Common/UI/Block/Dialog'
import { useInvokeAccountWhistleblowOperator } from 'Core/Authentication/API/AccountAPI'
import { Dialog } from '@headlessui/react'
import AlertView from 'Common/UI/Block/AlertView'
import classNames from 'classnames'

export const useInvokeAccountWhistleblowOperatorDialogStore = create((set) => ({
    show: false,
    ID: '',
    password: '',

    setShow: (value) => set(() => ({ show: value })),
    confirm: ({ ID }) => {
        let update = { show: true, ID }
        return set(() => update)
    },

    setPassword: (value) => set(() => ({ password: value })),
}))

export function InvokeAccountWhistleblowOperatorDialog({ onConfirm = () => null }) {
    const show = useInvokeAccountWhistleblowOperatorDialogStore(state => state.show)
    const setShow = useInvokeAccountWhistleblowOperatorDialogStore(state => state.setShow)

    const { ID, password } = useInvokeAccountWhistleblowOperatorDialogStore(state => ({ ID: state.ID, password: state.password }), shallow)
    const { setPassword } = useInvokeAccountWhistleblowOperatorDialogStore(state => ({ setPassword: state.setPassword }))

    const invokeAccountWhistleblowOperator = useInvokeAccountWhistleblowOperator({
        onSuccess: (response) => {
            onConfirm(transformResponseToSingle(response))
            setShow(false)
        }
    })
    const error = transformResponseError(invokeAccountWhistleblowOperator.error)

    const submit = (e) => {
        e.preventDefault()
        invokeAccountWhistleblowOperator.mutate({ ID, password })
    }

    return (
        <BaseDialog show={show} onClose={() => setShow(false)} >
            {({ cancelButtonRef }) => (
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <form onSubmit={submit}>
                        <div>
                            <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-rose-100`}>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                    <Icon.IconPhospor.UserMinus className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div>
                            </div>


                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                    Hapus Akses/Akun
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Silahkan lengkapi isian dibawah ini
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-7 grid grid-cols-1 gap-8 md:gap-3'>
                            <span className="p-float-label">
                                <Input.InputSecure
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={classNames('w-full', error['password'] ? 'p-invalid' : null)}
                                />
                                <label htmlFor="morningActivity">Kata Sandi</label>
                            </span>
                            <AlertView.ErrorInput errors={error['password']} />
                        </div>
                        <div className="flex justify-end gap-1.5 mt-4">
                            <Button.LoadableButton
                                variant='secondary'
                                type="button"
                                onClick={() => setShow(false)}
                                ref={cancelButtonRef}
                            >
                                Batal
                            </Button.LoadableButton>
                            <Button.LoadableButton
                                type='submit'
                                loading={invokeAccountWhistleblowOperator.isLoading}
                                loadingText='Memproses..'
                                onClick={() => {
                                    invokeAccountWhistleblowOperator.mutate({ ID, password })
                                }}
                            >
                                Simpan
                            </Button.LoadableButton>
                        </div>
                    </form>
                </div>
            )}
        </BaseDialog>
    )
}