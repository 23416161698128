import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import { TODAY } from 'Common/Constant/Date'
import Icon from 'Common/UI/Component/Icon'
import moment from 'moment'
import { transformResponseToList } from 'Common/Utility/formatter'
import Button from 'Common/UI/Component/Button'
import { useListMeetingByCalendarDate, useUpdateMeeting } from 'Core/Appointment/API/MeetingAPI'
import { Badge } from '@tremor/react'
import { humanizeRelativeFromDate } from 'Common/Utility/humanize'

export const useListMeetingByDateDialog = create((set) => ({
    show: false,
    date: TODAY,

    setShow: (value) => set(() => ({ show: value })),
    display: ({ date }) => {
        let update = { show: true, date }
        return set(() => update)
    },
}))

export function ListMeetingByDateDialog({ onConfirm = () => null }) {
    const show = useListMeetingByDateDialog(state => state.show)
    const { date } = useListMeetingByDateDialog(state => ({ date: state.date }), shallow)
    const setShow = useListMeetingByDateDialog(state => state.setShow)

    const listMeetingByCalenderDate = useListMeetingByCalendarDate({ date: date, expands: ['visitor', 'responseHistories'] })
    const meetings = transformResponseToList(listMeetingByCalenderDate.data)

    const cancelButtonRef = useRef(null)
    const dateAsMoment = moment(date, 'YYYY-MM-DD')

    const updateMeeting = useUpdateMeeting({
        onSuccess: () => {
            listMeetingByCalenderDate.refetch()
        }
    })

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setShow}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
                            <div>
                                <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-lime-100`}>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-sky-100">
                                        <Icon.IconPhospor.Users className="h-6 w-6 text-sky-600" aria-hidden="true" />
                                    </div>
                                </div>


                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Daftar Pertemuan  untuk {dateAsMoment.format('dddd, D MMMM Y')}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Berikut permintaan pertemuan anda pada tanggal {dateAsMoment.format('dddd, D MMMM Y')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='my-10'>
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="hidden lg:table-cell py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                ID
                                            </th>
                                            <th
                                                scope="col"
                                                className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
                                            >
                                                Kebutuhan/Keperluan
                                            </th>
                                            <th
                                                scope="col"
                                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                            >
                                                Pengunjung
                                            </th>
                                            <th
                                                scope="col"
                                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                            >
                                                Nomor Pengunjung
                                            </th>
                                            <th
                                                scope="col"
                                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                            >
                                                Institusi Pengunjung
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Jam
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Aksi</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {meetings.map(meeting => (
                                            <tr key={meeting.ID}>
                                                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                                    {meeting.ID}

                                                </td>
                                                <td className="w-full max-w-0 py-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                    {meeting.need}
                                                    <dl className="font-normal lg:hidden">
                                                        <dt className="sr-only">Title</dt>
                                                        <dd className="mt-1 truncate text-gray-700">
                                                            aa
                                                        </dd>
                                                        <dt className="sr-only sm:hidden">Email</dt>
                                                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                                                            aa
                                                        </dd>
                                                    </dl>
                                                </td>
                                                <td className="w-full max-w-0 py-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                                    {meeting.visitor.name}
                                                    <dl className="font-normal lg:hidden">
                                                        <dt className="sr-only">Nomor</dt>
                                                        <dd className="mt-1 truncate text-gray-700">
                                                            {meeting.visitor.phone}
                                                        </dd>
                                                        <dt className="sr-only sm:hidden">Institusi</dt>
                                                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                                                            {meeting.visitor.institution}
                                                        </dd>
                                                    </dl>
                                                </td>
                                                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                    {meeting.visitor.phone}
                                                </td>
                                                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                                    {meeting.visitor.institution}
                                                </td>
                                                <td className="px-3 py-4 text-sm text-gray-500 gap-1.5">
                                                    {moment(meeting.schedule.dateTime, 'YYYY-MM-DD HH:mm:ss').format('H:mm')}
                                                    {moment(meeting.schedule.dateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD') ? null :
                                                        <Badge size='xs' color="lime">
                                                            {humanizeRelativeFromDate(meeting.schedule.dateTime)}
                                                        </Badge>
                                                    }
                                                </td>
                                                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 ">
                                                    <div className='inline-flex gap-1.5'>
                                                        <Button.LoadableButton loading={updateMeeting.isLoading && updateMeeting.variables?.ID === meeting.ID && updateMeeting.variables?.response === 1} loadingText='Menerima ...' onClick={() => updateMeeting.mutateAsync({ ID: meeting.ID, response: 1 })} size='xs' variant={meeting.response?.status === 1 ? 'primary' : 'secondary'} icon={Icon.IconPhospor.CheckCircle} color="sky">
                                                            Terima
                                                        </Button.LoadableButton>

                                                        <Button.LoadableButton loading={updateMeeting.isLoading && updateMeeting.variables?.ID === meeting.ID && updateMeeting.variables?.response === 0} loadingText='Menolak ...' onClick={() => updateMeeting.mutateAsync({ ID: meeting.ID, response: 0 })} size='xs' variant={meeting.response?.status === 0 ? 'primary' : 'secondary'} icon={Icon.IconPhospor.CheckCircle} color="rose">
                                                            Tolak
                                                        </Button.LoadableButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-end gap-1.5">
                                <Button.LoadableButton
                                    variant='secondary'
                                    type="button"
                                    onClick={() => setShow(false)}
                                    ref={cancelButtonRef}
                                >
                                    Keluar
                                </Button.LoadableButton>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}