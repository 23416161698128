import { Badge } from "@tremor/react";
import { Pagination } from "Common/UI/Block/Pagination";
import Button from "Common/UI/Component/Button";
import Icon from "Common/UI/Component/Icon";
import BrowseDataLayout from "Common/UI/Layout/BrowseData/BrowsePaginateDataLayout";
import { transformResponseToList, transformResponseToPagination } from "Common/Utility/formatter";
import { SearchParams } from "Common/Utility/search-params";
import { useBrowseForm, useUpdateForm } from "Core/Form/API/FormAPI"
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import CardListLoader from "./CardListLoader";
import EmptyState from "Common/UI/Component/EmptyState";
import { CreateFormDialog, useCreateFormDialogStore } from "Core/Form/UI/Form/CreateFormDialog";
import { UpdateFormDialog, useUpdateFormDialogStore } from "Core/Form/UI/Form/UpdateFormDialog";

export default function BrowseFormRoute() {
    const confirmCreate = useCreateFormDialogStore(state => state.confirm)
    const confirmUpdate = useUpdateFormDialogStore(state => state.confirm)
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') || 1
    const show = searchParams.get('show') || 20
    const order = searchParams.get('order') || 'desc'
    const sort = searchParams.get('sort') || 'ID'
    const deleted = searchParams.get('deleted')
    const start = searchParams.get('start')
    const browseForm = useBrowseForm({ deleted, start, currentPage: page, pageSize: show, sort, order, expands: [] })
    const forms = transformResponseToList(browseForm.data)
    const pagination = transformResponseToPagination(browseForm.data)

    const updateForm = useUpdateForm({ onSuccess: () => browseForm.refetch() })

    return (
        <BrowseDataLayout
            title="Form"
            subtitle={<span>Manajemen data Form yang akan dirilis ke <a className="text-indigo-600 font-extrabold" href="https://form.mitrasdudi.id">https://form.mitrasdudi.id</a></span>}
            loading={browseForm.isLoading}
            pagination={pagination}
            filterOptions={[
                {
                    name: 'Status Hapus', multiple: false,
                    options: [
                        { name: 'Semua', parameters: { deleted: null } },
                        { name: 'Terhapus', parameters: { deleted: 'true' } },
                        { name: 'Tidak Terhapus', parameters: { deleted: 'false' } },
                    ]
                },
                {
                    name: 'Status Tayang', multiple: true,
                    options: [
                        { name: 'Tayang', parameters: { start: '1' } },
                        { name: 'Akan Tayang', parameters: { start: '0' } },
                        { name: 'Sudah Selesai', parameters: { start: '2' } },
                    ]
                },
            ]}
            sortOptions={[
                { name: 'Data Terbaru', parameters: { sort: 'ID', order: 'desc' } },
                { name: 'Data Terlama', parameters: { sort: 'ID', order: 'asc' } },
            ]}
            sidebar={(
                <div>
                    <Button.LoadableButton onClick={() => confirmCreate({})} color="indigo" icon={Icon.IconPhospor.PlusCircle}>
                        Buat Form Baru
                    </Button.LoadableButton>
                </div>
            )}
        >
            <div>
                <div className="flex justify-center">
                    <div className="sm:max-w-7xl sm:w-full pb-10 mx-4 2xl:mx-0">
                        {forms.length !== 0 || browseForm.isLoading ? null : <EmptyState.CardList />}
                        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {browseForm.isLoading ? <CardListLoader /> : null}

                            {forms.map((form) => (
                                <div className={classNames("max-w-sm rounded overflow-hidden shadow-lg", form.deleted ? "opacity-40 hover:opacity-100" : null)}>
                                    {form.banner ?
                                        <img className="w-full h-48 object-cover" src={form.bannerURL} alt="Gambar Banner" /> :
                                        <div className="w-full h-48 flex flex-col justify-center items-center bg-gray-400">
                                            <Icon.IconPhospor.ReceiptX className="text-white text-xl font-mono font-bold" />
                                            <span className="text-white text-md font-mono font-bold">Tidak ada Banner</span>
                                        </div>
                                    }

                                    <div className="px-6 pt-4 pb-2 relative flex flex-row justify-between">
                                        <Button.LoadableButton size="xs" variant="light" icon={Icon.IconPhospor.Browsers}>
                                            Pratinjau
                                        </Button.LoadableButton>
                                        <div className="flex gap-1.5">
                                            <Button.LoadableButton
                                                size="xs" className="group" variant="secondary" color="indigo"
                                                icon={Icon.IconPhospor.Textbox}
                                                onClick={() => confirmUpdate({ ID: form.ID })}
                                            >
                                                <span className="hidden group-hover:block">Ubah</span>
                                            </Button.LoadableButton>

                                            <Button.LoadableButton
                                                size="xs" className="group" variant="secondary"
                                                color={form.deleted ? "lime" : "rose"}
                                                icon={form.deleted ? Icon.IconPhospor.ArrowCounterClockwise : Icon.IconPhospor.TrashSimple}
                                                loading={updateForm.isLoading && updateForm.variables?.ID === form.ID}
                                                loadingText="Memproses"
                                                onClick={() => updateForm.mutate({ ID: form.ID, deleted: !form.deleted })}
                                            >
                                                <span className="hidden group-hover:block">
                                                    {form.deleted ? 'Balikan' : 'Hapus'}
                                                </span>
                                            </Button.LoadableButton>
                                        </div>
                                    </div>
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2">{form.name}</div>
                                        <div>
                                            {form.startStatus.type !== 0 ? null : (
                                                <Badge color="yellow" icon={Icon.IconPhospor.Calendar}>
                                                    Akan Tayang
                                                </Badge>
                                            )}
                                            {form.startStatus.type !== 1 ? null : (
                                                <Badge color="lime" icon={Icon.IconPhospor.Calendar}>
                                                    Sedang Tayang
                                                </Badge>
                                            )}
                                            {form.startStatus.type !== 2 ? null : (
                                                <Badge color="gray" icon={Icon.IconPhospor.CalendarX}>
                                                    Sudah Selesai
                                                </Badge>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>

            </div>

            <CreateFormDialog
                onConfirm={() => browseForm.refetch()}
            />
            <UpdateFormDialog
                onConfirm={() => browseForm.refetch()}
            />

        </BrowseDataLayout>


    )
}