import MainLayout from "Common/UI/Layout/Main/MainLayout";
import AccountRoutes from "Core/Authentication/UseCase/Route/Account/AccountRoutes";
import PageRoutes from "Core/Content/UseCase/Route/Page/PageRoutes";
import FormRoutes from "Core/Form/UseCase/Route/Form/FormRoutes";
import { DetailEmployeeProvider } from "Core/HumanResource/UseCase/Route/Employee/DetailEmployee/Context";
import EmployeeRoutes from "Core/HumanResource/UseCase/Route/Employee/EmployeeRoutes";
import { DetailReportProvider } from "Core/Whistleblow/UseCase/Route/Report/DetailReport/DetailReportContext";
import ReportRoutes from "Core/Whistleblow/UseCase/Route/Report/ReportRoutes";
import { Route, Routes, Navigate } from "react-router-dom";

import HumanResourceAttendance from './human-resource/attendance/calendar'
import HumanResourceHolidayDate from './human-resource/attendance/holiday-date'
import HumanResourceAgenda from './human-resource/attendance/agenda'
import ContentWeb from './content/web'
import { ROLE_SUPER_ADMINISTRATOR, useAuthentication } from "Common/Provider/Authentication";

export default function AuthenticatedRouters() {
    const { role } = useAuthentication()
    return (
        <MainLayout>
            <Routes>
                <Route path="/" element={<PageRoutes.HomeDashboardRoute />} />
                {role !== ROLE_SUPER_ADMINISTRATOR ? null :
                    <Route path="/authentication/account">
                        <Route index element={<AccountRoutes.BrowseAccountRoute />} />
                        <Route path=':id' element={<AccountRoutes.DetailAccountRoute />} />
                        <Route path=':id/*' element={<AccountRoutes.DetailAccountRoute />} />
                    </Route>
                }

                <Route path="/whistleblow/report">
                    <Route index element={<ReportRoutes.BrowseReportRoute />} />
                    <Route path=':id' element={
                        <DetailReportProvider>
                            <ReportRoutes.DetailReportRoute />
                        </DetailReportProvider>
                    } />
                    <Route path=':id/*' element={
                        <DetailReportProvider>
                            <ReportRoutes.DetailReportRoute />
                        </DetailReportProvider>
                    } />
                </Route>
                <Route path="/human-resource/employee">
                    <Route path="employee">
                        <Route index element={<EmployeeRoutes.BrowseEmployeeRoute />} />
                        <Route path=':id/*' element={
                            <DetailEmployeeProvider>
                                <EmployeeRoutes.DetailEmployeeRoute />
                            </DetailEmployeeProvider>
                        } />
                    </Route>
                </Route>

                <Route path="/human-resource/attendance">
                    <Route index element={<HumanResourceAttendance />} />
                    <Route path="holiday-date">
                        <Route index element={<HumanResourceHolidayDate />} />
                    </Route>
                    <Route path="agenda">
                        <Route index element={<HumanResourceAgenda />} />
                    </Route>
                </Route>

                <Route path="/form/form">
                    <Route index element={<FormRoutes.BrowseFormRoute />} />
                </Route>
                <Route path="/content">
                    <Route path="web">
                        <Route index element={<ContentWeb />} />
                    </Route>
                </Route>
                <Route path="*" element={<PageRoutes.NotFoundRoute />} />
            </Routes>
        </MainLayout>
    )
}