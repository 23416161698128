import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import Icon from 'Common/UI/Component/Icon'
import moment from 'moment'
import Input from 'Common/UI/Component/Input'
import { transformResponseError, transformResponseToSingle } from 'Common/Utility/formatter'
import Button from 'Common/UI/Component/Button'
import { BaseDialog } from 'Common/UI/Block/Dialog'
import { Dialog } from '@headlessui/react'
import { useDetailForm, useUpdateForm } from 'Core/Form/API/FormAPI'
import AlertView from 'Common/UI/Block/AlertView'
import { useEffect } from 'react'

export const useUpdateFormDialogStore = create((set) => ({
    show: false,
    ID: null,
    name: '',
    startDate: '',
    endDate: '',
    content: '',
    bannerFile: null,

    setShow: (value) => set(() => ({ show: value })),
    confirm: ({ ID }) => {
        let update = { ID, show: true }
        return set(() => update)
    },

    setName: (value) => set(() => ({ name: value })),
    setStartDate: (value) => set(() => ({ startDate: value })),
    setEndDate: (value) => set(() => ({ endDate: value })),
    setContent: (value) => set(() => ({ content: value })),
    setBannerFile: (value) => set(() => ({ bannerFile: value })),
}))

export function UpdateFormDialog({ onConfirm = () => null }) {
    const ID = useUpdateFormDialogStore(state => state.ID)
    const show = useUpdateFormDialogStore(state => state.show)
    const setShow = useUpdateFormDialogStore(state => state.setShow)


    const { name, content, startDate, endDate, bannerFile } = useUpdateFormDialogStore(state => ({ name: state.name, content: state.content, startDate: state.startDate, endDate: state.endDate, bannerFile: state.bannerFile }), shallow)
    const { setName, setStartDate, setEndDate, setContent, setBannerFile } = useUpdateFormDialogStore(state => ({ setName: state.setName, setStartDate: state.setStartDate, setEndDate: state.setEndDate, setContent: state.setContent, setBannerFile: state.setBannerFile }))

    const detail = useDetailForm(ID, {
        expands: [],
        enabled: false,
        onSuccess: (response) => {
            const data = transformResponseToSingle(response)
            setName(data.name)
            setStartDate(moment(data.startDate, 'YYYY-MM-DD').toDate())
            setEndDate(moment(data.endDate, 'YYYY-MM-DD').toDate())
            setContent(data.content)
        }
    })

    const updateForm = useUpdateForm({
        onSuccess: (response) => {
            onConfirm(transformResponseToSingle(response))
            setShow(false)
        }
    })

    useEffect(() => {
        detail.refetch()
    }, [ID])


    const submit = (e) => {
        e.preventDefault()
        updateForm.mutate({
            ID, 
            name, content,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            banner: bannerFile,
        })
    }

    const error = transformResponseError(updateForm.error)


    console.log('startDate', startDate)
    return (
        <BaseDialog show={show} onClose={() => setShow(false)} >
            {({ cancelButtonRef }) => (

                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">

                    <form onSubmit={submit}>
                        <div>
                            <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-lime-100`}>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <Icon.IconPhospor.Textbox className="h-6 w-6 text-green-600" aria-hidden="true" />
                                </div>
                            </div>


                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                    Buat Form Baru
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Silahkan lengkapi isian dibawah ini
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-7 grid grid-cols-1 md:grid-cols-2 gap-x-8 md:gap-x-4 gap-y-8'>
                            <div className='flex flex-col gap-y-2 md:col-span-2'>
                                <span className="p-float-label ">
                                    <Input.InputText value={name} onChange={(e) => setName(e.target.value)} className='w-full' />
                                    <label htmlFor="morningActivity">Nama</label>
                                </span>
                                <AlertView.ErrorInput errors={error['name']} />
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <span className="p-float-label">
                                    <Input.InputDate value={startDate} dateFormat="yy-mm-dd" onChange={(e) => setStartDate(e.target.value)} className='w-full' />
                                    <label htmlFor="morningActivity">Tanggal Mulai</label>
                                </span>
                                <AlertView.ErrorInput errors={error['startDate']} />
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <span className="p-float-label">
                                    <Input.InputDate value={endDate} dateFormat="yy-mm-dd" onChange={(e) => setEndDate(e.target.value)} minDate={startDate} className='w-full' />
                                    <label htmlFor="morningActivity">Tanggal Selesai</label>
                                </span>
                                <AlertView.ErrorInput errors={error['endDate']} />
                            </div>
                            <div className='flex flex-col gap-y-2 md:col-span-2'>
                                <span className="p-float-label ">
                                    <Input.InputText value={content} onChange={(e) => setContent(e.target.value)} className='w-full' />
                                    <label htmlFor="morningActivity">Konten (Formfacade)</label>
                                </span>
                                <AlertView.ErrorInput errors={error['content']} />
                            </div>
                        </div>
                        <div className="flex justify-end gap-1.5 mt-4">
                            <Button.LoadableButton
                                variant='secondary'
                                type="button"
                                onClick={() => setShow(false)}
                                ref={cancelButtonRef}
                            >
                                Batal
                            </Button.LoadableButton>
                            <Button.LoadableButton
                                type='submit'
                                loading={updateForm.isLoading}
                                loadingText='Memproses..'
                            >
                                Simpan
                            </Button.LoadableButton>
                        </div>
                    </form>
                </div>
            )}
        </BaseDialog>
    )
}