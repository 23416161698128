import { Bold, Card, Flex, List, ListItem, Metric, Text } from "@tremor/react";
import moment from "moment";
import Loading from "Common/UI/Component/Loading";
import { useBrowseHolidayDate } from "Core/HumanResource/API/HolidayDateAPI";
import { transformResponseToList } from "Common/Utility/formatter";
import { Link } from "react-router-dom";

export default function CardMonthHoliday({ calenderAttendance, monthHolidays, momentPeriod }) {
    const startDate = momentPeriod.format('YYYY-01-01')
    const endDate = momentPeriod.format('YYYY-12-31')
    const browseHolidayDate = useBrowseHolidayDate({ startDate, endDate, sort: 'date', order: 'asc', pageSize: 200, currentPage: 1 })
    const holidayDates = transformResponseToList(browseHolidayDate.data)

    const startOfMonth = momentPeriod.clone().startOf('month')
    const endOfMonth = momentPeriod.clone().endOf('month')


    const previousMonthHolidayDates = holidayDates.filter(holidayDate => moment(holidayDate.date, 'YYYY-MM-DD').isBefore(startOfMonth))
    const nextMonthHolidayDates = holidayDates.filter(holidayDate => moment(holidayDate.date, 'YYYY-MM-DD').isAfter(endOfMonth))

    return (
        <Card>
            <Text>Jadwal Libur Bulan sekarang</Text>
            <Metric>{momentPeriod.format('MMMM YYYY')}</Metric>
            <Flex className="mt-6">
                <Text>
                    <Bold>Nama</Bold>
                </Text>
                <Text>
                    <Bold>Tanggal</Bold>
                </Text>
            </Flex>
            <List className="mt-1">
                {!previousMonthHolidayDates.length ? null : previousMonthHolidayDates.slice(-1).map(holidayDate => (
                    <ListItem key={`${holidayDate.date}-${holidayDate.title}`} className="">
                        <Flex justifyContent="start" className="truncate space-x-2.5">
                            <Text className="truncate">{holidayDate.title}</Text>
                        </Flex>
                        <Text>{moment(holidayDate.date, 'YYYY-MM-DD').format('dddd, D MMM')}</Text>
                    </ListItem>
                ))}
                {calenderAttendance.isLoading ? <Loading.Spinner /> :
                    monthHolidays.map(holiday =>
                        <ListItem key={`${holiday.date}-${holiday.title}`} className="font-semibold">
                            <Flex justifyContent="start" className="truncate space-x-2.5">
                                <Text className="truncate text-indigo-400">{holiday.title}</Text>
                            </Flex>
                            <Text className="text-indigo-400">{moment(holiday.date, 'YYYY-MM-DD').format('dddd, D MMM')}</Text>
                        </ListItem>
                    )
                }
                {!nextMonthHolidayDates.length ? null : nextMonthHolidayDates.slice(0, 2).map(holidayDate => (
                    <ListItem key={`${holidayDate.date}-${holidayDate.title}`} className="">
                        <Flex justifyContent="start" className="truncate space-x-2.5">
                            <Text className="truncate">{holidayDate.title}</Text>
                        </Flex>
                        <Text>{moment(holidayDate.date, 'YYYY-MM-DD').format('dddd, D MMM')}</Text>
                    </ListItem>
                ))}
            </List>
            <div className="flex justify-center mt-0.5 text-sm text-gray-400">
                <Link to="/human-resource/attendance/holiday-date">
                    Lihat Semua
                </Link>
            </div>
        </Card>
    )
}