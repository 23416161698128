import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAuthentication } from 'Common/Provider/Authentication'
import { useBrowseEmployee, useUpdateEmployee } from 'Core/HumanResource/API/EmployeeAPI'
import { transformResponseError, transformResponseToList, transformResponseToPagination } from 'Common/Utility/formatter'
import BrowsePaginateDataLayout from 'Common/UI/Layout/BrowseData/BrowsePaginateDataLayout'
import Table from 'Common/UI/Component/Table'
import Logo from 'Common/UI/Component/Logo'
import { useConfirmDialogStore } from 'Common/UI/Block/ConfirmDialog'
import Icon from 'Common/UI/Component/Icon'
import { Badge } from '@tremor/react'
import moment from 'moment'
import Loader from 'Common/UI/Component/Loader'


export default function BrowseEmployeeRoute() {
    const { role } = useAuthentication()
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page') || 1
    const show = searchParams.get('show') || 20
    const roleSearch = searchParams.get('role')
    const haveAccess = searchParams.get('haveAccess')
    const sort = searchParams.get('sort') || 'name'
    const order = searchParams.get('order') || 'asc'
    const isInActive = searchParams.get('isInActive')
    const keyword = searchParams.get('keyword')

    const browseEmployee = useBrowseEmployee({ expands: ['access', 'accessRole', 'position', 'salary'], currentPage: page, keyword, haveAccess, sort, order, isInActive, pageSize: show })
    const updateEmployee = useUpdateEmployee({
        onError: (error) => setErrorPasswordConfirmDialog(transformResponseError(error)['password']),
        onSuccess: (response) => {
            browseEmployee.refetch()
        }
    })
    const pagination = transformResponseToPagination(browseEmployee.data)
    const employees = transformResponseToList(browseEmployee.data)
    const { confirmDialog, setErrorPasswordConfirmDialog } = useConfirmDialogStore(state => ({ confirmDialog: state.confirm, setErrorPasswordConfirmDialog: state.setErrorPassword }))


    useEffect(() => {
        browseEmployee.refetch()
    }, [page, show, roleSearch, haveAccess, sort, order, isInActive])

    return (
        <BrowsePaginateDataLayout
            title="Karyawan"
            subtitle={<span>Manajemen data Karywan pada semua sistem Mitras DUDI yang terkoneksi pada  <a className="text-indigo-600 font-extrabold" href="https://connect.mitrasdudi.id">Mitras DUDI Connect</a></span>}
            loading={browseEmployee.isLoading}
            pagination={pagination}
            filterOptions={[
                {
                    name: 'Status Akses', multiple: false,
                    options: [
                        { name: 'Semua', parameters: { haveAccess: null } },
                        { name: 'Memiliki Akses', parameters: { haveAccess: 'true' } },
                        { name: 'Tidak Memiliki Akses', parameters: { haveAccess: 'false' } },
                    ]
                },
                {
                    name: 'Status Aktif', multiple: false,
                    options: [
                        { name: 'Semua', parameters: { isInActive: null } },
                        { name: 'Terhapus', parameters: { isInActive: 'true' } },
                        { name: 'Aktif', parameters: { isInActive: 'false' } },
                    ]
                },
            ]}
            sortOptions={[
                { name: 'Data Terbaru', parameters: { sort: 'ID', order: 'desc' } },
                { name: 'Data Terlama', parameters: { sort: 'ID', order: 'asc' } },
                { name: 'A-Z', parameters: { sort: 'name', order: 'asc' } },
                { name: 'Z-A', parameters: { sort: 'name', order: 'desc' } },
            ]}
            sidebar={(
                <div>

                </div>
            )}
        >

            <div className="mx-10 mb-3">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5  md:rounded-lg">
                    {browseEmployee.isLoading ? <Loader.TableLoader /> :
                        <Table.Data
                            items={employees}
                            keySelector={item => item.ID}
                            columns={[
                                {
                                    header: {
                                        name: 'ID',
                                    },
                                    value: {
                                        select: (item) => item.ID,
                                        renderer: Table.Renderer.Simple
                                    },
                                    option: {
                                        primary: true,
                                    }
                                },

                                {
                                    header: {
                                        name: 'Nama dan Surel',
                                    },
                                    value: {
                                        select: (item) => item.name,
                                        renderer: Table.Renderer.User,
                                        renderExtra: {
                                            email: (item) => item.email,
                                            imageURL: (item) => item.access?.avatarURL,
                                        }
                                    },
                                    option: {
                                        showOn: 'lg'
                                    }

                                },
                                {
                                    header: {
                                        name: 'PNS/PPNPN',
                                    },
                                    value: {
                                        select: (item) => item.employeeType,
                                        renderer: Table.Renderer.Raw,
                                        renderExtra: {
                                            render: ({ value }) => (
                                                <div>
                                                    <Badge icon={Icon.IconPhospor.SealCheck} color={value === 'PNS' ? 'lime' : 'gray'}>{value}</Badge>
                                                </div>
                                            )
                                        }
                                    },
                                    option: {
                                        showOn: 'xl'
                                    }

                                },
                                {
                                    header: {
                                        name: 'NIK',
                                    },
                                    value: {
                                        select: (item) => item.NIK,
                                        renderer: Table.Renderer.Simple
                                    },
                                    option: {
                                        showOn: 'sm'
                                    }

                                },
                                {
                                    header: {
                                        name: 'Profil',
                                    },
                                    value: {
                                        select: (item) => item.genderLabel,
                                        renderer: Table.Renderer.Raw,
                                        renderExtra: {
                                            render: ({ value, item }) => (
                                                <div>
                                                    <Badge icon={item.gender === 1 ? Icon.IconPhospor.GenderFemale : Icon.IconPhospor.GenderMale} color={item.gender === 1 ? 'pink' : 'sky'}>{value}</Badge>
                                                    {!item.bornDate ? null :
                                                        <div>
                                                            <span>{moment().diff(moment(item.bornDate, 'YYYY-MM-DD'), 'years')} </span>Tahun
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    },
                                    option: {
                                        showOn: 'sm'
                                    }

                                },
                                {
                                    header: {
                                        name: 'Bergabung Sejak',
                                    },
                                    value: {
                                        select: (item) => item.created,
                                        renderer: Table.Renderer.Timestamp
                                    },
                                    option: {
                                        showOn: 'xl'
                                    }
                                },
                                {
                                    header: {
                                        name: 'Akses',
                                    },
                                    value: {
                                        select: (item) => item.accessRole?.employee ? 'Memiliki Akses' : 'Tidak Memiliki Akses',
                                        renderer: Table.Renderer.Raw,
                                        renderExtra: {
                                            render: ({ value, item }) => (
                                                <div>
                                                    <Badge icon={Icon.IconPhospor.IdentificationBadge} color={item.accessRole?.employee ? 'lime' : 'gray'}>{value}</Badge>
                                                    {!item.accessRole ? null :
                                                        <div className='mt-0.5 flex flex-wrap max-w-sm gap-1.5'>
                                                            {!item.accessRole.formOperator ? null : <Badge icon={Icon.IconPhospor.Crown} color={'amber'}>Operator Form</Badge>}
                                                            {!item.accessRole.whistleblowOperator ? null : <Badge icon={Icon.IconPhospor.Crown} color={'amber'}>Operator WBS</Badge>}
                                                            {!item.accessRole.attendanceOperator ? null : <Badge icon={Icon.IconPhospor.Crown} color={'amber'}>Operator Presensi</Badge>}
                                                            {!item.accessRole.humanResourceOperator ? null : <Badge icon={Icon.IconPhospor.Crown} color={'amber'}>Operator HRD</Badge>}
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    },
                                    option: {
                                        showOn: 'sm'
                                    }

                                },
                            ]}

                            actions={[
                                Table.Action.ChatOnWhatsapp({ phoneNumberSelector: item => item.phoneNumber?.startsWith('0') ? `62${item.phoneNumber.substring(1)}` : item.phoneNumber, messageCompose: item => `Hai, ${item.name}` }),
                                Table.Action.View({ basePath: '/human-resource/employee/employee' }),
                                Table.Action.Archive({
                                    archived: item => item.isDeleted,
                                    confirm: (item) => confirmDialog({
                                        title: item.isDeleted ? `Mengaktifkan kembali karyawan dengan nama ${item.name}` : `Mengarsipkan karyawan dengan nama ${item.name}`,
                                        message: 'Apakah anda yakin akan melakukan ini?',
                                        icon: item.isDeleted ? Icon.IconPhospor.Archive : Icon.IconPhospor.ArchiveBox,
                                        iconColor: 'rose',
                                        onConfirm: (password) => updateEmployee.mutateAsync({ ID: item.ID, isDeleted: !item.isDeleted , password, passwordConfirmation: password }),
                                        usingPassword: true,
                                    }),
                                }),
                            ]}

                        />
                    }

                </div>
            </div>
        </BrowsePaginateDataLayout>

    )
}
