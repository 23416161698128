import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import Icon from 'Common/UI/Component/Icon'
import moment from 'moment'
import Input from 'Common/UI/Component/Input'
import { transformResponseError, transformResponseToSingle } from 'Common/Utility/formatter'
import Button from 'Common/UI/Component/Button'
import { BaseDialog } from 'Common/UI/Block/Dialog'
import { Dialog } from '@headlessui/react'
import { useCreateForm } from 'Core/Form/API/FormAPI'
import AlertView from 'Common/UI/Block/AlertView'
import { FileUploader } from "react-drag-drop-files";

export const useCreateFormDialogStore = create((set) => ({
    show: false,
    name: '',
    startDate: '',
    endDate: '',
    content: '',
    bannerFile: null,

    setShow: (value) => set(() => ({ show: value })),
    confirm: ({ }) => {
        let update = { show: true }
        return set(() => update)
    },

    setName: (value) => set(() => ({ name: value })),
    setStartDate: (value) => set(() => ({ startDate: value })),
    setEndDate: (value) => set(() => ({ endDate: value })),
    setContent: (value) => set(() => ({ content: value })),
    setBannerFile: (value) => set(() => ({ bannerFile: value })),
}))

export function CreateFormDialog({ onConfirm = () => null }) {
    const show = useCreateFormDialogStore(state => state.show)
    const setShow = useCreateFormDialogStore(state => state.setShow)

    const { name, content, startDate, endDate, bannerFile } = useCreateFormDialogStore(state => ({ name: state.name, content: state.content, startDate: state.startDate, endDate: state.endDate, bannerFile: state.bannerFile }), shallow)
    const { setName, setStartDate, setEndDate, setContent, setBannerFile } = useCreateFormDialogStore(state => ({ setName: state.setName, setStartDate: state.setStartDate, setEndDate: state.setEndDate, setContent: state.setContent, setBannerFile: state.setBannerFile }))

    const createForm = useCreateForm({
        onSuccess: (response) => {
            onConfirm(transformResponseToSingle(response))
            setShow(false)
        }
    })

    const submit = (e) => {
        e.preventDefault()
        createForm.mutate({
            name, content,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            banner: bannerFile,
        })
    }

    const error = transformResponseError(createForm.error)

    return (
        <BaseDialog show={show} onClose={() => setShow(false)} >
            {({ cancelButtonRef }) => (

                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">

                    <form onSubmit={submit}>
                        <div>
                            <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-lime-100`}>
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <Icon.IconPhospor.Textbox className="h-6 w-6 text-green-600" aria-hidden="true" />
                                </div>
                            </div>


                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                    Buat Form Baru
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Silahkan lengkapi isian dibawah ini
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-7 grid grid-cols-1 md:grid-cols-2 gap-x-8 md:gap-x-4 gap-y-8'>
                            <div className='flex flex-col gap-y-2 md:col-span-2'>
                                <span className="p-float-label ">
                                    <Input.InputText value={name} onChange={(e) => setName(e.target.value)} className='w-full' />
                                    <label htmlFor="morningActivity">Nama</label>
                                </span>
                                <AlertView.ErrorInput errors={error['name']} />
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <span className="p-float-label">
                                    <Input.InputDate value={startDate} dateFormat="yy-mm-dd" onChange={(e) => setStartDate(e.target.value)} className='w-full' />
                                    <label htmlFor="morningActivity">Tanggal Mulai</label>
                                </span>
                                <AlertView.ErrorInput errors={error['startDate']} />
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <span className="p-float-label">
                                    <Input.InputDate value={endDate} dateFormat="yy-mm-dd" onChange={(e) => setEndDate(e.target.value)} minDate={startDate} className='w-full' />
                                    <label htmlFor="morningActivity">Tanggal Selesai</label>
                                </span>
                                <AlertView.ErrorInput errors={error['endDate']} />
                            </div>
                            <div className='flex flex-col gap-y-2 md:col-span-2'>
                                <span className="p-float-label ">
                                    <Input.InputText value={content} onChange={(e) => setContent(e.target.value)} className='w-full' />
                                    <label htmlFor="morningActivity">Konten (Formfacade)</label>
                                </span>
                                <AlertView.ErrorInput errors={error['content']} />
                            </div>

                            <div className='flex flex-col gap-y-2 md:col-span-2'>
                                <div>
                                    <label for="description" className="block mb-2 text-sm font-medium text-gray-900 ">
                                        Gambar Banner
                                    </label>
                                    <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
                                        {bannerFile ?
                                            <div className='relative'>
                                                <img className='w-full border-gray-300 border-dashed rounded-md object-cover h-48' src={URL.createObjectURL(bannerFile)} />
                                                <button className='absolute top-0 right-0 bg-rose-600 ring ring-white w-6 h-6 rounded-full flex items-center justify-center' onClick={() => setBannerFile(null)}>
                                                    <Icon.IconPhospor.XCircle className='w-5 h-5 text-white' />
                                                </button>
                                            </div> :
                                            <FileUploader
                                                multiple={false}
                                                handleChange={(file) => setBannerFile(file)}
                                                name="file"
                                                types={['JPG', 'PNG', 'BMP']}
                                            >

                                                <div className>
                                                    <div role="presentation" tabIndex={0} className="mt-10 flex flex-col justify-center items-center mx-10">
                                                        <div className>
                                                            <div className="max-w-lg flex justify-center md:px-36 px-2 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md ">
                                                                <div className="space-y-1 text-center">
                                                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                    <div className="flex text-sm text-gray-600">
                                                                        <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                            <span>Upload a file</span>
                                                                            <input accept="image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation" multiple type="file" tabIndex={-1} style={{ display: 'none' }} />
                                                                        </label>
                                                                        <p className="pl-1">or drag and drop</p>
                                                                    </div>
                                                                    <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FileUploader>
                                        }
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end gap-1.5 mt-4">
                            <Button.LoadableButton
                                variant='secondary'
                                type="button"
                                onClick={() => setShow(false)}
                                ref={cancelButtonRef}
                            >
                                Batal
                            </Button.LoadableButton>
                            <Button.LoadableButton
                                type='submit'
                                loading={createForm.isLoading}
                                loadingText='Memproses..'
                            >
                                Simpan
                            </Button.LoadableButton>
                        </div>
                    </form>
                </div>
            )}
        </BaseDialog>
    )
}