import { requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useCalendarMeeting({ onSuccess, startDate, endDate }) {
    const path = `/appointment/appointment/meeting/calendar`
    const data = useQuery({
        queryKey: [...(path.split('/')), startDate, endDate],
        queryFn: () => requestGet(path, { startDate, endDate }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useListMeetingByCalendarDate({ onSuccess, date, expands = [] }) {
    const path = `/appointment/appointment/meeting/calendar/${date}`
    const data = useQuery({
        queryKey: [path, date, expands],
        queryFn: () => requestGet(path, { expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useBrowseMeeting({ onSuccess, startDate, endDate, sort = 'datetime', order = 'asc', expands = [] }) {
    const path = `/appointment/appointment/meeting`
    const data = useQuery({
        queryKey: [path, startDate, endDate, sort, order, expands],
        queryFn: () => requestGet(path, { startDate, endDate, sort, order, expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useUpdateMeeting({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, response }) => {
            const path = `/appointment/appointment/meeting/${ID}`;
            return requestPost(path, { response })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}