import { Listbox, Transition } from "@headlessui/react";
import Icon from "Common/UI/Component/Icon";
import { generateArray } from "Common/Utility/generator";
import { SearchParams } from "Common/Utility/search-params";
import classNames from "classnames";
import moment from "moment";
import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";


const YEARS = generateArray(20).map((_, index) => moment().subtract(10-index, 'year').format('YYYY'))

export default function ListYear({ }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const year = searchParams.get('year')
    const momentYear = year ? moment(year, 'YYYY') : moment()
    

    return (
        <Listbox value={momentYear.format('YYYY')} onChange={(value) => setSearchParams(SearchParams.updates(searchParams, { year: value }))}>
            {({ open }) => (
                <>
                    <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                    <div className="relative">
                        <div className="inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                            <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                                <div className="relative inline-flex items-center bg-indigo-500 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                                    <Icon.IconSolid.CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    <p className="ml-2.5 text-sm font-medium">{momentYear.format('YYYY')}</p>
                                </div>
                                <Listbox.Button className="relative inline-flex items-center bg-indigo-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">
                                    <span className="sr-only">Change published status</span>
                                    <Icon.IconSolid.ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                </Listbox.Button>
                            </div>
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {YEARS.map((yearSelection) => (
                                    <Listbox.Option
                                        key={yearSelection}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-indigo-500' : 'text-gray-900',
                                                'cursor-default select-none relative p-4 text-sm'
                                            )
                                        }
                                        value={yearSelection}
                                    >
                                        {({ selected, active }) => (
                                            <div className="flex flex-col">
                                                <div className="flex justify-between">
                                                    <p className={selected ? 'font-semibold' : 'font-normal'}>Hari Libur</p>
                                                    {selected ? (
                                                        <span className={active ? 'text-white' : 'text-indigo-500'}>
                                                            <Icon.IconSolid.CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <p className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'text-xl mt-2')}>
                                                    {yearSelection}
                                                </p>
                                            </div>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}