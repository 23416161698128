import { transformResponseToInfiniteHaveNext, transformResponseToInfiniteList, transformResponseToSingle } from "Common/Utility/formatter"
import { useCreateActivityLog, useInfiniteScrollActivityLog } from "Core/Whistleblow/API/ActivityLogAPI"
import { useDetailReport, useUpdateReport } from "Core/Whistleblow/API/ReportAPI"
import { createContext, useContext } from "react"
import { useParams } from "react-router-dom/dist"

const DetailReportContext = createContext()

export function DetailReportProvider({ children }) {
    const { id } = useParams()
    const detailReport = useDetailReport(id, { expands: [] })
    const report = transformResponseToSingle(detailReport.data)

    const infiniteScrollActivityLog = useInfiniteScrollActivityLog({ reportID: id, pageSize: 10, order: 'created', order: 'desc' })
    const activityLogs = transformResponseToInfiniteList(infiniteScrollActivityLog.data)
    const isActivityLogHaveMore = transformResponseToInfiniteHaveNext(infiniteScrollActivityLog.data)

    const createActivityLog = useCreateActivityLog({
        onSuccess: (response) => {
            detailReport.refetch()
            infiniteScrollActivityLog.refetch()
        },
        onError: (error) => null,
        headers: { Role: 'Employee' }
    })

    const updateReport = useUpdateReport({
        onSuccess: () => {
            detailReport.refetch()
            infiniteScrollActivityLog.refetch()
        }
    })

    const shared = {
        id,
        detailReport, updateReport, createActivityLog,
        report,
        infiniteScrollActivityLog, isActivityLogHaveMore,
        activityLogs,
    }
    return (
        <DetailReportContext.Provider value={shared}>
            {children}
        </DetailReportContext.Provider>
    )
}

export function useDetailReportContext() {
    return useContext(DetailReportContext)
}
