
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { toInitial, transformResponseToPagination } from 'Common/Utility/formatter'
import { useBrowseAccount } from 'Core/Authentication/API/AccountAPI'
import Icon from 'Common/UI/Component/Icon'
import { useSearchParams } from 'react-router-dom/dist'


export default function RoleSelectionBlock({ role, title, color }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const browseAccount = useBrowseAccount({ expands: ['role'], currentPage: 1, pageSize: 1, role: role })
    const pagination = transformResponseToPagination(browseAccount.data)
    const currentRoles = (searchParams.get('role') || '').split(',')

    return (
        <div className="relative col-span-1 flex shadow-sm rounded-md">
            <div
                className={classNames(
                    color,
                    'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                )}
            >
                {toInitial(title)}
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div className="flex-1 px-4 py-2 text-sm truncate">
                    <button className="text-gray-900 font-medium hover:text-gray-600">
                        {title}
                    </button>
                    <p className="text-gray-500">{pagination.itemCount} Members</p>
                </div>
                <Menu as="div" className="flex-shrink-0 pr-2">
                    <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open options</span>
                        <Icon.IconSolid.EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                            <div className="py-1">
                                <span className='block px-4 py-2 text-sm w-full text-left text-gray-400'>Ulangi Pencarian dengan</span>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => setSearchParams({ role })}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm w-full text-left'
                                            )}
                                        >
                                            Memiliki Ini
                                        </button>
                                    )}
                                </Menu.Item>
                                {role === '' ? null :
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => setSearchParams({ role: `-${role}` })}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm w-full text-left'
                                                )}
                                            >

                                                Tidak Memiliki Ini
                                            </button>
                                        )}
                                    </Menu.Item>
                                }
                            </div>
                            {role === '' ? null :
                                <div className="py-1">
                                    <span className='block px-4 py-2 text-sm w-full text-left text-gray-400'>Tambahkan Pencarian dengan</span>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => setSearchParams({ ...Object.fromEntries(searchParams), role: [...currentRoles, role].join(',') })}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm w-full text-left'
                                                )}
                                            >
                                                Memiliki Ini
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => setSearchParams({ ...Object.fromEntries(searchParams), role: [...currentRoles, `-${role}`].join(',') })}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm w-full text-left'
                                                )}
                                            >
                                                Tidak Memiliki Ini
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            }
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    )
}