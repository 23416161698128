import { requestDelete, requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useCreateAccount({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ email, password }) => {
            const path = `/authentication/account/account`;
            return requestPost(path, { email, password })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useBrowseAccount({ onSuccess, keyword, role, currentPage, pageSize, sort = 'datetime', order = 'asc', expands = [] }) {
    const path = `/authentication/account/account`
    const data = useQuery({
        queryKey: [path, keyword, role, currentPage, pageSize, sort, order, expands],
        queryFn: () => requestGet(path, { keyword, role, currentPage, pageSize, sort, order, expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useUpdateAccount({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, email, password, passwordConfirmation }) => {
            const path = `/authentication/account/account/${ID}`;
            return requestPost(path, { email, password, passwordConfirmation })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useDetailAccount(ID, { expands, onSuccess, onError }) {
    const path = `/authentication/account/account/${ID}`
    const data = useQuery({
        queryKey: [path, expands],
        queryFn: () => requestGet(path, { expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useAssignAccountSuperAdministrator({ onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, password }) => {
            const path = `/authentication/account/account/${ID}/super-administrator`;
            return requestPost(path, { password })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useInvokeAccountSuperAdministrator({ onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, password }) => {
            const path = `/authentication/account/account/${ID}/super-administrator`;
            return requestPost(path, { password })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useAssignAccountWhistleblowOperator({ onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, password, type }) => {
            const path = `/authentication/account/account/${ID}/whistleblow-operator`;
            return requestPost(path, { password, type })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useInvokeAccountWhistleblowOperator({ onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, password }) => {
            const path = `/authentication/account/account/${ID}/whistleblow-operator`;
            return requestPost(path, { password })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useAssignAccountEmployee({ onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ID, password, employeeID}) => {
            const path = `/authentication/account/account/${ID}/employee`;
            return requestPost(path, { password, employeeID})
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useInvokeAccountEmployee({ onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ID, password }) => {
            const path = `/authentication/account/account/${ID}/employee`;
            return requestDelete(path, { password})
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}