import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { transformResponseError } from 'Common/Utility/formatter'
import { PlusSmallIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { InputText } from 'Common/UI/Component/Input'
import { useCreateEmployeeContact } from 'Core/HumanResource/API/EmployeeContactAPI'
import Icon from 'Common/UI/Component/Icon'

export default function ModalCreateEmployeeContact({ employee, open, onClose, onConfirm }) {
    const cancelButtonRef = useRef(null)
    const [type, setType] = useState('')
    const [contact, setContact] = useState('')

    const createEmployeeBankAccount = useCreateEmployeeContact({ onSuccess: () => setType('') || setContact('') || onConfirm(), })

    const error = transformResponseError(createEmployeeBankAccount.error)

    const submit = (event) => {
        event.preventDefault()
        createEmployeeBankAccount.mutate({ employeeID: employee.ID, type, contact })
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <PlusSmallIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0  sm:text-left w-full mx-4">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Tambah Kontak Alternatif
                                        </Dialog.Title>

                                        <div>
                                            <div className="text-center">
                                                <svg
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 48 48"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                                                    />
                                                </svg>
                                                <h2 className="mt-2 text-lg font-medium text-gray-900">Pilih Tipe Kontak</h2>

                                            </div>

                                        </div>
                                        <div className="mt-10">

                                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                                                {['E-Mail', 'Phone'].map((typeOption) => {
                                                    return (
                                                        <div
                                                            key={typeOption}
                                                            onClick={() => {
                                                                setType(typeOption)
                                                            }}
                                                            className={classNames(
                                                                typeOption === type ? 'border-transparent' : 'border-gray-300',
                                                                typeOption === type ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                                                                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                                            )}
                                                        >
                                                            <div className="flex-1 flex">
                                                                <div className="flex flex-col">
                                                                    <span className="mt-1 flex items-center text-2xl text-gray-500">
                                                                        {typeOption}
                                                                    </span>

                                                                </div>
                                                            </div>
                                                            {typeOption === type && (
                                                                <Icon.IconOutline.CheckCircleIcon
                                                                    className={classNames('h-5 w-5 text-indigo-600')}
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            <div
                                                                className={classNames(
                                                                    typeOption === type ? 'border' : 'border-2',
                                                                    typeOption === type ? 'border-indigo-500' : 'border-transparent',
                                                                    'absolute -inset-px rounded-lg pointer-events-none'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <form onSubmit={submit}>
                                            <div className='mt-10 space-y-2'>
                                                <InputText
                                                    label="Kontak"
                                                    value={contact}
                                                    onChange={value => setContact(value)}
                                                    errorMessage={error['body.contact']}
                                                />
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <form onSubmit={submit}>
                                    <button
                                        type="submit"


                                        disabled={createEmployeeBankAccount.isLoading}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"

                                    >

                                        {createEmployeeBankAccount.isLoading ?
                                            <span className='flex flex-row gap-2 items-center'><Icon.IconSolid.ArrowPathIcon className='h-3 w-3 animate-spin' />  Saving..</span> :
                                            'Assign'
                                        }

                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        ref={cancelButtonRef}
                                        onClick={() => onClose()}
                                    >
                                        Cancel
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}