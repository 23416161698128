import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { useCurrency as currencyFormatter } from 'react-hook-currency';
import { useState } from 'react';
import { humanizeNumber } from 'Common/Utility/humanize';

const InputSecure = props => <Password feedback={false} inputClassName="w-full" {...props} />
const InputDate = props => <Calendar inputClassName="w-full" {...props} />
const InputFormat = ({ label, value, onChange, placeholder, errorMessage = null, layoutProps, numberProps = null, ...props }) => {
    const formatterProps = numberProps || { style: 'decimal', precision: 0 }
    const precision = formatterProps.precision || 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { onClick, onChange: onChangeNative, onKeyDown, format, toNumber, } = currencyFormatter(formatterProps);
    const [nativeValue, setNativeValue] = useState(() => format(`${humanizeNumber(value / ((Math.pow(10, 2 - precision))))}`));

    return (
        <InputText
            onChange={event => {
                onChangeNative(event);
                setNativeValue(event.target.value);
                onChange(toNumber(event.target.value))
            }}
            onChangeNative={true}
            onKeyDown={onKeyDown}
            onClick={onClick}
            value={nativeValue}
            {...props}
        />
    )
}

export { InputSecure, InputText, InputNumber, InputTextarea, InputDate, InputFormat }
export default { InputSecure, InputText, InputNumber, InputTextarea, InputDate, InputFormat }