import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import Icon from 'Common/UI/Component/Icon'
import NavigationPanel from './NavigationPanel'
import UserPanelBlock from './UserPanelBlock'
import Logo from 'Common/UI/Component/Logo'
import { useAuthentication } from 'Common/Provider/Authentication'

export default function SidebarBlock({ sidebarOpen, setSidebarOpen }) {
    const { user, signOut } = useAuthentication()
    const navigate = useNavigate()
    const [isNeedConfirmationForLogout, setIsNeedConfirmationForLogout] = useState(false)
    const cancelButtonRef = useRef(null)

    return (
        <Fragment>
            <Transition.Root show={isNeedConfirmationForLogout} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 bt-10 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setIsNeedConfirmationForLogout}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                        <Icon.IconOutline.PowerIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            Anda yakin ingin keluar dari sistem sebagai ({user.email}) ?
                                        </Dialog.Title>

                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                                        onClick={() => {
                                            signOut()
                                            navigate('/')
                                        }}
                                    >
                                        Keluar
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        onClick={() => setIsNeedConfirmationForLogout(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <Icon.IconOutline.XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <Logo.CompanyLogo className="h-24 w-auto" />
                                    <div>
                                        <h1 className='text-2xl font-bold text-indigo-600'>MDIS</h1>
                                        <h1 className='text-xs font-medium text-indigo-600'>Mitras DUDI Information System</h1>
                                        <h1 className='text-xs font-light'>(Versi 2.0)</h1>
                                    </div>
                                </div>
                                <nav className="mt-5 px-2 space-y-1">
                                    <NavigationPanel />
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                <UserPanelBlock
                                    onLogout={() => setSidebarOpen(false) || setIsNeedConfirmationForLogout(true)}
                                />
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 ">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
                    <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div className="flex flex-col text-center  justify-center items-center flex-shrink-0 px-4 mb-10">
                            <div className="shadow-md rounded-full w-24 h-24 flex items-center justify-center" >
                                <Logo.CompanyLogo width="6rem" height="6rem" />
                            </div>
                            <div className='mt-5'>
                                <h1 className='text-2xl font-bold text-indigo-600'>MDIS</h1>
                                <h1 className='text-xs font-medium text-indigo-600'>Mitras DUDI Information System</h1>
                                <h1 className='text-xs font-light'>(Versi 2.0)</h1>
                            </div>
                        </div>
                        <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                            <NavigationPanel />
                        </nav>
                    </div>
                    <div className="flex-shrink-0 flex border-t border-gray-200 p1-1">
                        <UserPanelBlock
                            onLogout={() => setSidebarOpen(false) || setIsNeedConfirmationForLogout(true)}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}