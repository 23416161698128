import { requestGet, requestPost } from "Common/Provider/RestClient"
import { useInfiniteQuery, useMutation, useQuery, } from "@tanstack/react-query"

export function useCreateActivityLog({ onSuccess, onError, headers = {} }) {
    const mutation = useMutation({
        mutationFn: ({ action, reportID, content }) => {
            const path = `/whistleblow/whistleblow/activity-log`;
            return requestPost(path, {
                action, reportID, content
            }, headers)
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    });
    return mutation
}

export function useBrowseActivityLog({ reportID, pageSize, currentPage, onSuccess, sort = 'created', order = 'asc', expands = [] }) {
    const path = `/whistleblow/whistleblow/activity-log`
    const data = useQuery({
        queryKey: [path, reportID, pageSize, currentPage, sort, order, expands],
        queryFn: () => requestGet(path, { reportID, pageSize, currentPage, sort, order, expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useInfiniteScrollActivityLog({ reportID, pageSize = 10, onSuccess, sort = 'created', order = 'asc', expands = [] }) {
    const path = `/whistleblow/whistleblow/activity-log`
    const data = useInfiniteQuery({
        queryKey: [path, reportID, sort, order, expands],
        queryFn: ({ pageParam = 1 }) => requestGet(path, { reportID, pageSize, currentPage: pageParam, sort, order, expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        getNextPageParam: (lastPage, allPages) => {
            const nextPage = allPages.length + 1
            return nextPage;
        },
        // getPreviousPageParam: (firstPage, allPages) => firstPage.prevCursor,
    })
    return data
}