import { Badge, Card, Col, Flex, Grid, Metric, Tab, TabGroup, TabList, TabPanel, TabPanels, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Text, Title } from "@tremor/react";
import AttendanceReportCardComponent from "./AttendanceReportCardComponent";
import { DutyReportCardComponent } from "./DutyReportCardComponent";
import { useBrowseReport } from "Core/Whistleblow/API/ReportAPI";
import { transformResponseToList } from "Common/Utility/formatter";
import { humanizeDate, humanizeDateTime, humanizeRelativeFromDate, humanizeRelativeToDate, humanizeTime } from "Common/Utility/humanize";
import Button from "Common/UI/Component/Button";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Icon from "Common/UI/Component/Icon";
import moment from "moment";
import SelectPeriod from "./SelectPeriod";
import { generatePeriod } from "Common/Utility/generator";
import { authenticationState } from "Common/Provider/Authentication";

const periods = generatePeriod()

export default function HomeDashboardRoute() {
    const [selectedCategory, setSelectedCategory] = useState('WBS');
    const browseReport = useBrowseReport({ sort: 'date', order: 'desc', pageSize: 10 })
    const reports = transformResponseToList(browseReport.data)
    const [period, setPeriod] = useState(moment().format('YYYY-MM'))

    return (
        <main className="mx-1 md:mx-4">
            <Title>Dashboard</Title>
            <Text>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</Text>

            <Grid numColsLg={6} className="gap-6 mt-6">
                {/* Main section */}
                <Col numColSpanLg={4}>
                    <Card className="h-full">
                        <TabGroup>
                            <TabList 
                                className="mb-8"
                            >
                                <Tab icon={Icon.IconPhospor.Bell}>Whistleblow</Tab>
                                <Tab icon={Icon.IconPhospor.BookBookmark}>Buku Tamu</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Flex justifyContent="start" className="space-x-2">
                                        <Title>Laporan terakhir</Title>
                                        <Badge color="gray">{reports.length}</Badge>
                                    </Flex>
                                    <Text className="mt-2">
                                        Laporan WBS terakhir yang dikirimkan.
                                    </Text>

                                    <Table className="mt-6">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeaderCell>ID</TableHeaderCell>
                                                <TableHeaderCell>Waktu Lapor</TableHeaderCell>
                                                <TableHeaderCell>Nama yang Dilapor</TableHeaderCell>
                                                <TableHeaderCell>Laporan</TableHeaderCell>
                                                <TableHeaderCell>Lokasi</TableHeaderCell>
                                                <TableHeaderCell>Waktu Kejadian</TableHeaderCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {browseReport.isLoading ? null :
                                                reports.map((report) => (
                                                    <TableRow key={report.ID}>
                                                        <TableCell>{report.ID}</TableCell>
                                                        <TableCell className="text-right">
                                                            <div>
                                                                <div>
                                                                    {humanizeDateTime(report.created)}
                                                                </div>
                                                                <div>
                                                                    <Badge size="xs" color="gray">{humanizeRelativeFromDate(report.created)}</Badge>

                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>{report.personName}</TableCell>
                                                        <TableCell>{report.title}</TableCell>
                                                        <TableCell>
                                                            {report.location}
                                                        </TableCell>
                                                        <TableCell className="text-right">
                                                            <div>
                                                                <div>
                                                                    {humanizeDate(report.date)}
                                                                </div>
                                                                <div>
                                                                    <Badge size="xs" color="gray">{humanizeRelativeFromDate(`${report.date} ${report.time}`)}</Badge>
                                                                    {' '}{humanizeTime(report.time)}
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link to={`/whistleblow/report/${report.ID}`}>
                                                                <Button.LoadableButton icon={Icon.IconPhospor.Eye} size="xs" variant="secondary" color="gray">
                                                                    Lihat Detail
                                                                </Button.LoadableButton>
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TabPanel>
                                <TabPanel>
                                    <Flex justifyContent="start" className="space-x-2">
                                        <Title>Purchases</Title>
                                        <Badge color="gray">8</Badge>
                                    </Flex>
                                    <Text className="mt-2">Overview of this month's purchases</Text>

                                    <Table className="mt-6">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeaderCell>ID</TableHeaderCell>
                                                <TableHeaderCell>Nama yang Dilapor</TableHeaderCell>
                                                <TableHeaderCell>Laporan</TableHeaderCell>
                                                <TableHeaderCell>Lokasi</TableHeaderCell>
                                                <TableHeaderCell>Tanggal/Waktu</TableHeaderCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {browseReport.isLoading ? null :
                                                reports.map((report) => (
                                                    <TableRow key={report.ID}>
                                                        <TableCell>{report.ID}</TableCell>
                                                        <TableCell>{report.personName}</TableCell>
                                                        <TableCell>{report.title}</TableCell>
                                                        <TableCell>
                                                            {report.location}
                                                        </TableCell>
                                                        <TableCell className="text-right">
                                                            {humanizeDate(report.date)} {report.time}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link to={`/whistleblow/report/${report.ID}`}>
                                                                <Button.LoadableButton icon={Icon.IconPhospor.Eye} size="xs" variant="secondary" color="gray">
                                                                    Lihat Laporan
                                                                </Button.LoadableButton>
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TabPanel>
                            </TabPanels>

                        </TabGroup>
                    </Card>
                </Col>

                {/* KPI sidebar */}
                <Col numColSpanLg={2}>
                    <div className="space-y-6">
                        <AttendanceReportCardComponent />
                        <DutyReportCardComponent />
                        <Card>
                            <Text>Unduh Laporan </Text>
                            <Metric>Presensi</Metric>
                            <div className="flex gap-2 mt-4">
                                <SelectPeriod periods={periods} value={period} onChange={value => setPeriod(value)} />
                                <a href={`https://keuangan.mitrasdudi.id/en/human-resource/attendance/attendance/export/${period}?token=${authenticationState.token}&requestAs=Attendance-Operator`}>
                                    <Button.LoadableButton icon={Icon.IconPhospor.FileXls} variant={'secondary'} className={""}>
                                        Unduh Laporan Presensi <b>{moment(period, 'YYYY-MM').format('MMMM YYYY')}</b>
                                    </Button.LoadableButton>
                                </a>
                            </div>
                        </Card>
                    </div>
                </Col>
            </Grid>
        </main>
    )
}