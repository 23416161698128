import { humanizeNumber, humanizeTimeFromDatetime } from "Common/Utility/humanize"
import { humanizeDate } from "Common/Utility/humanize"
import { humanizeRelativeFromDate } from "Common/Utility/humanize"
import classNames from "classnames"
import { Fragment } from "react"
import Icon from "./Icon"
import Button from "./Button"
import { cn } from "Common/Utility/class-name"
import { Link } from "react-router-dom"
import Loader from "./Loader"
import EmptyState from "./EmptyState"
import Clock from "./Clock"
import Status from "./Status"


const Data = ({ loading = false, columns = [], items = [], actions = [], keySelector }) => {
    return (
        <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
                <tr>
                    {columns.map(({ header, option }, index) => (
                        <th
                            key={index}
                            scope="col"
                            className={classNames(
                                "py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
                                ({
                                    'xs': '',
                                    'sm': 'hidden sm:table-cell',
                                    'md': 'hidden md:table-cell',
                                    'lg': 'hidden lg:table-cell',
                                    'xl': 'hidden xl:table-cell',
                                }[option.showOn || 'xs']),
                                header.className,
                            )}
                        >
                            {header.name}
                        </th>
                    ))}

                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Actions</span>
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {loading || items.length > 0 ? null : <EmptyState.Table colSpan={columns.length} />}
                {!loading ? null : <Loader.RowLoader columnSize={columns.length} />}
                {items.map((item) => (
                    <tr key={keySelector(item)}>
                        {columns.map(({ value, option }, index) => (
                            <td
                                key={`${keySelector(item)}-${index}`}
                                scope="col"
                                className={cn(
                                    "w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6",
                                    ({
                                        'xs': '',
                                        'sm': 'hidden sm:table-cell',
                                        'md': 'hidden md:table-cell',
                                        'lg': 'hidden lg:table-cell',
                                        'xl': 'hidden xl:table-cell',
                                    }[option.showOn ?? 'xs']),
                                    value.className,
                                )}
                            >

                                {value.renderer(value.select)(item, { renderExtra: value.renderExtra, event: value.event })}

                                {!option.primary ? null :
                                    <dl className="font-normal lg:hidden">
                                        {columns.filter(column => !column.option.primary).map((column, columnIndex) => (
                                            <Fragment key={`${keySelector(item)}-${index}-${columnIndex}`}>
                                                <dt
                                                    className={classNames(
                                                        "mt-1 font-semibold",
                                                        ({
                                                            'xs': '',
                                                            'sm': 'block sm:hidden',
                                                            'md': 'block md:hidden',
                                                            'lg': 'block lg:hidden',
                                                            'xl': 'block xl:hidden',
                                                        }[column.option.showOn ?? 'xs']),
                                                    )}
                                                >
                                                    {column.header.name}
                                                </dt>
                                                <dd
                                                    className={classNames(
                                                        "-mt-0.5 truncate text-gray-500",
                                                        ({
                                                            'xs': '',
                                                            'sm': 'block sm:hidden',
                                                            'md': 'block md:hidden',
                                                            'lg': 'block lg:hidden',
                                                            'xl': 'block xl:hidden',
                                                        }[column.option.showOn ?? 'xs']),
                                                    )}
                                                >
                                                    {Renderer.Simple(column.value.select)(item)}
                                                </dd>
                                            </Fragment>
                                        ))}
                                    </dl>
                                }
                            </td>
                        ))}

                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="flex flex-col md:flex-row gap-1.5">
                                {actions.map((action, index) => (
                                    <Fragment key={index}>
                                        {action.render(item)}
                                    </Fragment>
                                ))}
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table >
    )
}

const Renderer = {
    Simple: (selector) => (item) => selector(item),
    Price: (selector) => (item) => <span className="font-mono"><span className="text-gray-500">Rp.</span> {humanizeNumber(selector(item), 0, 0)}</span>,
    CountDown: (selector) => (item, { event }) => {
        const interval = selector(item)
        if (!interval || interval <= 0) return <span className="flex text-center justify-center text-rose-600">Time Out</span>
        return (
            <Clock.CountDown
                interval={interval}
                onComplete={event.onComplete}
            />
        )
    },
    Status: (selector) => (item) => <Status.Transaction value={selector(item)} />,
    Timestamp: (selector) => (item) => (
        <div className="flex items-center">
            <div className="">
                <div className="font-medium text-gray-400">{humanizeDate(selector(item))}</div>
                <div className="text-gray-300 flex flex-col gap-1 items-left">
                    <span className="font-medium text-gray-900">{humanizeTimeFromDatetime(selector(item))}</span>
                    <span className="flex flex-row items-center gap-0.5">
                        <Icon.IconPhospor.Info className="mt-0.5" />
                        {humanizeRelativeFromDate(selector(item))}
                    </span>
                </div>
            </div>
        </div>
    ),
    User: (selector) => (item, { renderExtra }) => (
        !selector(item) ? null :
            <div className="flex items-center">
                {renderExtra.imageURL(item) ?
                    <div className="h-10 w-10 flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={renderExtra.imageURL(item)} alt="" />
                    </div> :
                    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </span>
                }


                <div className="ml-4">
                    <div className="font-medium text-gray-900">{selector(item)}</div>
                    <div className="text-gray-500">{renderExtra.email(item)}</div>
                </div>
            </div>
    ),
    Image: (selector) => (item, { renderExtra }) => (
        !selector(item) ? null :
            <div className="flex items-center">
                <div className="h-10 w-10 flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={selector(item)} alt="" />
                </div>
                {!renderExtra?.name ? null :
                    <div className="ml-4">
                        <div className="font-medium text-gray-900">{renderExtra.name(item)}</div>
                    </div>
                }

            </div>
    ),
    Raw: (selector) => (item, { renderExtra }) => !selector(item) ? null : renderExtra.render({ value: selector(item), item }),
}

const Action = {
    View: ({ basePath }) => ({
        render: (item) => (
            <Link to={`${basePath}/${item.ID}`}>
                <Button.LoadableButton size="xs" variant="secondary" icon={Icon.IconPhospor.Eye} color="sky">
                    View
                </Button.LoadableButton>
            </Link>
        )
    }),
    ChatOnWhatsapp: ({ phoneNumberSelector, messageCompose }) => ({
        render: (item) => (
            !phoneNumberSelector(item) ?
                <Button.LoadableButton size="xs" variant="secondary" disabled={true} icon={Icon.IconPhospor.WhatsappLogo} color="green">
                    Chat on WhatsApp
                </Button.LoadableButton> :
                <a target="_blank" href={`https://api.whatsapp.com/send?phone=${phoneNumberSelector(item)}&text=${encodeURIComponent(messageCompose(item))}`}>
                    <Button.LoadableButton size="xs" variant="secondary" icon={Icon.IconPhospor.WhatsappLogo} color="green">
                        Chat on WhatsApp
                    </Button.LoadableButton>
                </a>
        )
    }),
    HardDelete: ({ confirm, request }) => ({
        render: (item) => (
            <Button.LoadableButton size="xs" onClick={() => confirm(item)} variant="secondary" icon={Icon.IconPhospor.Trash} color="rose">
                Delete
            </Button.LoadableButton>
        )
    }),
    Archive: ({ confirm, request, archived }) => ({
        render: (item) => (
            <Button.LoadableButton size="xs" onClick={() => confirm(item)} variant={archived(item) ? "primary" : "secondary"} icon={archived(item) ? Icon.IconPhospor.Archive : Icon.IconPhospor.ArchiveBox} color="rose">
                {archived(item) ? 'Aktifkan' : 'Non-Aktifkan'}
            </Button.LoadableButton>
        )
    }),
}

export default { Data, Renderer, Action }