import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import ToggleIconComponent from "./ToggleIconComponent";
import { useMatch } from "react-router-dom";
import NavLinkComponent from "./NavLinkComponent";
import Icon from "Common/UI/Component/Icon";

export default function NavLinkBlock({ name, to, icons = [], items = [] }) {
    let match = useMatch(`${to}/*`);
    return (
        <Disclosure defaultOpen={match} as="div" className="space-y-1">
            {({ open }) => (
                <Fragment>
                    <Disclosure.Button
                        className={classNames(
                            false
                                ? 'bg-gray-100 text-gray-900'
                                : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group w-full flex items-center px-2 py-2  text-left text-sm font-medium rounded-md focus:outline-none'
                        )}
                    >
                        <ToggleIconComponent
                            activeIcon={icons.length >= 2 ? icons[1] : Icon.IconSolid.HomeIcon}
                            inactiveIcon={icons.length >= 1 ? icons[0] : Icon.IconOutline.HomeIcon}
                            isActive={open}
                            className={classNames(
                                match !== null ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                        />
                        {name}
                    </Disclosure.Button>
                    <Disclosure.Panel className="space-y-1">
                        {items.map((item, index) => (
                            <NavLinkComponent
                                className="ml-6"
                                end={true}
                                key={index}
                                to={item.to}
                                icons={item.icons}
                                name={item.name}
                                subtitle={item.subtitle}
                            />
                        ))}
                    </Disclosure.Panel>
                </Fragment>
            )}
        </Disclosure>
    )
}