import { requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useBrowseEmployeeSalary({
    sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, keyword,
    employeeID,
    onSuccess
}) {
    const path = `/human-resource/employee/employee-salary`
    const data = useQuery({
        queryKey: [path.split('/'), sort, order, pageSize, keyword, currentPage],
        queryFn: () => requestGet(path, {
            sort, order, keyword, pageSize, currentPage: currentPage || 1, expand: expands.join(','),
            employeeID
        }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useCreateEmployeeSalary({ onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ employeeID, baseAmount, items }) => {
            const parsed = new FormData();
            parsed.append('employeeID', employeeID)
            parsed.append('baseAmount', baseAmount)
            const list = items || []
            list.map((item, index) => {
                parsed.append(`items[${index}][name]`, item.name)
                parsed.append(`items[${index}][amount]`, item.amount)
            })
            return requestPost(`/human-resource/employee/employee-salary`, parsed)
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}
