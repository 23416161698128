import { requestDelete, requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useBrowseEmployeeContact({
    sort = 'created', order = 'desc', expands = [], pageSize = 20, currentPage = 1, keyword,
    employeeID,
    onSuccess
}) {
    const path = `/human-resource/employee/employee-contact`
    const data = useQuery({
        queryKey:[path, sort, order, pageSize, keyword, currentPage],
        queryFn: () => requestGet(path, {
            sort, order, keyword, pageSize, currentPage: currentPage || 1, expand: expands.join(','),
            employeeID
        }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useCreateEmployeeContact({ onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ contact, type, employeeID}) => {
            const path = `/human-resource/employee/employee-contact`;
            return requestPost(path, { contact, type, employeeID })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}



export function useDeleteEmployeeContact(ID, { onSuccess = () => null, onError }) {
    const path = `/human-resource/employee/employee-contact/${ID}`
    const mutation = useMutation({
        mutationFn: ({ }) => requestDelete(path, {}),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}