import { requestGet } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useBrowseBank({ sort = 'code', order = 'asc', expands = [], pageSize = 20, currentPage = 1, keyword, onSuccess }) {
    const path = `/human-resource/employee/bank`

    const data = useQuery({
        queryKey: [path.split('/'), sort, order, pageSize, keyword, currentPage],
        queryFn: () => requestGet(path, { sort, order, keyword, pageSize, currentPage: currentPage || 1, expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}
