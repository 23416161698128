import { RadioGroup } from "@headlessui/react";
import Button from "Common/UI/Component/Button";
import Icon from "Common/UI/Component/Icon";
import { transformResponseToList } from "Common/Utility/formatter";
import { useBrowseEmployee } from "Core/HumanResource/API/EmployeeAPI";
import classNames from "classnames";
import {  useState } from "react";

export default function EmployeeSelect({ value, onChange }) {
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [haveAccess, setHaveAccess] = useState(false)
    const browseEmployee = useBrowseEmployee({ pageSize: 9, currentPage: page, haveAccess, keyword, isInActive: false })
    const employees = transformResponseToList(browseEmployee.data)

    const search = () => browseEmployee.refetch({ cancelRefetch: true })
    return (
        <div>
            <div>
                <div className="text-center">
                    <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                    <h2 className="mt-2 text-lg font-medium text-gray-900">Pemilihan Karyawan</h2>
                    <p className="mt-1 text-sm text-gray-500">Silahkan pilih karyawan yang ada</p>
                </div>
                <form onSubmit={search} className="mt-6 sm:flex sm:items-center" action="#">
                    <label htmlFor="emails" className="sr-only">
                        Pencarian
                    </label>
                    <div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
                        <input
                            type="text"
                            value={keyword}
                            onChange={e => setKeyword(e.target.value)}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-32 sm:text-sm border-gray-300 rounded-md"
                            placeholder="Ketik kata kunci pencarian. Dapat berupa nama, NIK, NIK, email"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                            <span className="h-4 w-px bg-gray-200" aria-hidden="true" />
                            <label htmlFor="role" className="sr-only">
                                Filter Akses
                            </label>
                            <select
                                value={haveAccess}
                                onChange={e => setHaveAccess(e.target.value)}
                                name="role"
                                className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                            >
                                <option value={true}>Memiliki Akses</option>
                                <option value={false}>Belum memiliki Akses</option>
                            </select>
                        </div>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                        <Button.LoadableButton loading={browseEmployee.isLoading} loadingText="Mencari ..." icon={Icon.IconPhospor.MagnifyingGlass}>
                            Cari
                        </Button.LoadableButton>
                    </div>
                </form>
            </div>
            <div className="mt-10">
                <RadioGroup value={value} onChange={onChange}>
                    <RadioGroup.Label className="text-base font-medium text-gray-900">Pilih karyawan yang belum memiliki akses</RadioGroup.Label>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                        {employees.map((employee) => (
                            <RadioGroup.Option
                                key={employee.ID}
                                value={employee}
                                className={({ checked, active }) =>
                                    classNames(
                                        checked ? 'border-transparent' : 'border-gray-300',
                                        active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                                        'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <>
                                        <div className="flex-1 flex">
                                            <div className="flex flex-col">
                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                    {employee.name}
                                                </RadioGroup.Label>
                                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                </RadioGroup.Description>
                                                <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                                    {employee.NIK}
                                                </RadioGroup.Description>
                                            </div>
                                        </div>
                                        <Icon.IconSolid.CheckCircleIcon
                                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                            aria-hidden="true"
                                        />
                                        <div
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                'absolute -inset-px rounded-lg pointer-events-none'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </div>
        </div>
    )
}