
import { Fragment } from 'react';
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Toaster } from 'react-hot-toast';
import AuthenticatedRouters from './AuthenticatedRouters';
import GuestRouters from './GuestRouters';
import { ConfirmDialog } from 'Common/UI/Block/ConfirmDialog';
import { MediaSizeDetector } from 'Common/UI/Component/DebugUI';
import { AuthenticationProvider, useAuthentication } from 'Common/Provider/Authentication';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import './index.css';

export const queryClient = new QueryClient()

function Router() {
  const { user } = useAuthentication()

  if (user === false) {
    return null
  }

  return (
    <Fragment>
      {user ? <AuthenticatedRouters /> : <GuestRouters />}
    </Fragment>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthenticationProvider>
          <Router />
          <Toaster />

          <ConfirmDialog />
        </AuthenticationProvider>
      </BrowserRouter>
      <MediaSizeDetector />
    </QueryClientProvider>
  );
}

export default App;
