import { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Flex, ProgressBar, Text } from "@tremor/react";
import { classNames } from "primereact/utils";
import moment from "moment";
import CalenderView from "Common/UI/Block/CalenderView";
import Icon from "Common/UI/Component/Icon";
import { transformResponseToSingle } from "Common/Utility/formatter";
import Loading from "Common/UI/Component/Loading";
import { generateArray } from "Common/Utility/generator";
import { useConfirmDialogStore } from "Common/UI/Block/ConfirmDialog";
import { useCalendarAttendance, useCreateAttendance, useDateAttendance, useDownloadAvailableEmployeeAttendance } from "Core/HumanResource/API/AttendanceAPI";
import { useCreateAttendanceDialogStore } from "Core/HumanResource/UI/Attendance/CreateAttendanceActivityDialog";
import { useCalendarMeeting } from "Core/Appointment/API/MeetingAPI";
import { useListMeetingByDateDialog } from "Core/Appointment/UI/Meeting/ListMeetingByDateDialog";
import { useAuthentication } from "Common/Provider/Authentication";
import CardMonthAttendanceRate from "./UI/CardMonthAttendanceRate";
import { humanizePercentage } from "Common/Utility/humanize";
import { colorBasedOnAttendanceRate } from "Core/HumanResource/Utility/Colorize";
import TableEmployeeAttendance from "./UI/TableEmployeeAttendance";
import { SearchParams } from "Common/Utility/search-params";
import CardMonthHoliday from "./UI/CardMonthHoliday";

export default function Route() {
    const confirm = useConfirmDialogStore(state => state.confirm)
    const displayListMeetingByDate = useListMeetingByDateDialog(state => state.display)
    const confirmCreateAttendance = useCreateAttendanceDialogStore(state => state.confirm)
    const { user, token, role } = useAuthentication()
    const [searchParams, setSearchParams] = useSearchParams();
    const period = searchParams.get('period')
    const attendanceDate = searchParams.get('attendance.date')
    const momentPeriod = period ? moment(period, 'YYYY-MM') : moment()
    const startDate = momentPeriod.startOf('month').format('YYYY-MM-DD')
    const endDate = momentPeriod.endOf('month').format('YYYY-MM-DD')


    const calenderAttendance = useCalendarAttendance({ startDate, endDate, properties: ['attendances', 'holidays', 'duties', 'isHoliday', 'attendanceRate'] })
    const calenderAttendances = transformResponseToSingle(calenderAttendance.data)

    const downloadAvailableEmployeeAttendance = useDownloadAvailableEmployeeAttendance({ start: momentPeriod.startOf('month').format('YYYY-MM'), token: token })
    const availableDownloadEmployeeAttendance = transformResponseToSingle(downloadAvailableEmployeeAttendance.data)


    const calenderMeeting = useCalendarMeeting({ startDate, endDate })
    const calenderMeetings = transformResponseToSingle(calenderMeeting.data)

    const createAttendance = useCreateAttendance({
        onSuccess: () => {
            calenderAttendance.refetch()
        }
    })

    const monthHolidays = calenderAttendance.isLoading ? [] : Object.values(calenderAttendances).map(({ holidays }, index) => holidays).flat()

    return (
        <div>
            <div className="m-1.5 md:m-10 gap-8 flex flex-col">
                <div className="grid grid-cols-4 gap-3">
                    <div className="col-span-4 md:col-span-3">
                        <CalenderView
                            title="Kalender Presensi Bulanan"
                            additionalMenu={(
                                <div>
                                    <a href={`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : 'https://keuangan.mitrasdudi.id/api/en'}/service/download/attendance/employee-attendance?token=${token}&start=${period}&requestAs=${role}`}>
                                        <Button loading={downloadAvailableEmployeeAttendance.isLoading} disabled={!availableDownloadEmployeeAttendance?.available} color="sky" size="xs" variant={"secondary"} a icon={Icon.IconPhospor.FileXls}>
                                            Unduh Log Presensi <span className="font-bold">Bulan {momentPeriod.format('MMM, YYYY')}</span>
                                        </Button>
                                    </a>
                                </div>
                            )}
                            selectableDateOn="date-label"
                            selectedDate={attendanceDate}
                            onSelectedDate={({ date }) => setSearchParams(SearchParams.updates(searchParams, { 'attendance.date': date }))}
                            initialCursorDate={period ? moment(period, 'YYYY-MM-DD') : moment()}
                            onChangeDays={(days) => setSearchParams({ period: moment(days.find(item => item.isCurrentMonth).date, 'YYYY-MM-DD').format('YYYY-MM') })}
                            classItem={day => !calenderAttendance.isLoading && calenderAttendances[day.date]?.isHoliday ? 'bg-rose-100' : null}
                            renderItem={day => (
                                <div className="pt-3" >
                                    {!day.isCurrentMonth ? null :
                                        calenderAttendance.isLoading || calenderAttendance.isLoading ? <Loading.Spinner size="20px" /> :
                                            (!calenderAttendances[day.date] || calenderAttendances[day.date].isHoliday) ? null :
                                                <Fragment>
                                                    <Flex>
                                                        <Text className="font-mono">{calenderAttendances[day.date].attendanceRate.value}/{calenderAttendances[day.date].attendanceRate.population} &bull; 45%</Text>
                                                        <Text>{humanizePercentage(calenderAttendances[day.date].attendanceRate.percentage / 100)}</Text>
                                                    </Flex>
                                                    <ProgressBar value={calenderAttendances[day.date].attendanceRate.percentage} color={colorBasedOnAttendanceRate(calenderAttendances[day.date].attendanceRate.percentage)} className="mt-3" />
                                                </Fragment>
                                    }
                                </div>
                            )}
                            renderItemMobile={day => {
                                return (
                                    <button onClick={() => confirmCreateAttendance({ date: day.date })}>
                                        {!day.isCurrentMonth ? null :
                                            calenderAttendance.isLoading || calenderAttendance.isLoading ? <Loading.Spinner size="20px" /> :
                                                calenderAttendances[day.date]?.isHoliday ?
                                                    <Fragment>
                                                        <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                            {calenderAttendances[day.date]?.holidays.length === 0 ? null :
                                                                generateArray(calenderAttendances[day.date]?.holidays.length).map((_, index) =>
                                                                    <div key={index} className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", 'bg-rose-400')} />
                                                                )
                                                            }
                                                        </div>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                            <div className={classNames("mx-0.5 mb-1 h-1.5 w-1.5 rounded-full", `bg-${colorBasedOnAttendanceRate(calenderAttendances[day.date].attendanceRate.percentage)}-400`)} />
                                                        </div>
                                                    </Fragment>
                                        }
                                    </button>
                                )
                            }}

                        />
                    </div>
                    <div className="col-span-4 md:col-span-1 mt-0 md:mt-28 flex gap-3 flex-col">
                        <CardMonthAttendanceRate title="Presensi Bulan sekarang" subtitle={`Rerata Presensi • ${momentPeriod.clone().format('MMM YYYY')}`} period={momentPeriod.clone().format('YYYY-MM')} />
                        <CardMonthAttendanceRate title="Presensi Bulan kemarin" subtitle={`Rerata Presensi • ${momentPeriod.clone().subtract(1, 'month').format('MMM YYYY')}`} period={momentPeriod.clone().subtract(1, 'month').format('YYYY-MM')} />
                        <CardMonthHoliday monthHolidays={monthHolidays} calenderAttendance={calenderAttendance} momentPeriod={momentPeriod} />
                    </div>
                </div>

                <TableEmployeeAttendance />
            </div>
        </div>
    )
}