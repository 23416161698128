import { requestDelete, requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useCreateEmployee({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ email, password }) => {
            const path = `/human-resource/employee/employee`;
            return requestPost(path, { email, password })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useBrowseEmployee({ onSuccess, keyword, haveAccess, isInActive, currentPage, pageSize, sort = 'ID', order = 'desc', expands = [] }) {
    const path = `/human-resource/employee/employee`
    const data = useQuery({
        queryKey: [path, keyword, haveAccess, isInActive, currentPage, pageSize, sort, order, expands.join(',')],
        queryFn: () => requestGet(path, { keyword, haveAccess, isInActive, currentPage, pageSize, sort, order, expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useUpdateEmployee({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, email, password, passwordConfirmation, isDeleted }) => {
            const path = `/human-resource/employee/employee/${ID}`;
            return requestPost(path, { email, password, passwordConfirmation, isDeleted })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useDetailEmployee(ID, { expands, onSuccess, onError }) {
    const path = `/human-resource/employee/employee/${ID}`
    const data = useQuery({
        queryKey: [path, expands],
        queryFn: () => requestGet(path, { expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useBrowseEmployeeFile(ID, { onSuccess }) {
    const path = `/human-resource/employee/employee/${ID}/file`
    const data = useQuery([
        ...(path.split('/')),
    ], () => requestGet(path, {

    }), {
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useUploadEmployeeFile(ID, { onSuccess = () => null, onError }) {
    const mutation = useMutation(({
        file,
    }) => requestPost(`/human-resource/employee/employee/${ID}/file`, {
        file
    }), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}


export function useDeleteEmployeeFile(ID, { onSuccess = () => null, onError }) {
    const path = `/human-resource/employee/employee/${ID}/file`
    const mutation = useMutation(({ name }) => requestDelete(`${path}/${name}`, {}), {
        onSuccess: (response) => onSuccess(response),
        onError: (error) => onError ? onError(error) : null
    })
    return mutation
}