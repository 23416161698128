import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useConfirmDialogStore } from "Common/UI/Block/ConfirmDialog";
import { SearchParams } from "Common/Utility/search-params";
import { useBrowseHolidayDate, useDeleteHolidayDate } from "Core/HumanResource/API/HolidayDateAPI";
import Table from "Common/UI/Component/Table";
import { Pagination } from "Common/UI/Block/Pagination";
import { transformResponseToList, transformResponseToPagination } from "Common/Utility/formatter";
import Loader from "Common/UI/Component/Loader";
import Icon from "Common/UI/Component/Icon";
import { humanizeDate } from "Common/Utility/humanize";
import ListYear from "./UI/ListYear";

export default function Route() {
    const { confirmDialog, setErrorPasswordConfirmDialog } = useConfirmDialogStore(state => ({ confirmDialog: state.confirm, setErrorPasswordConfirmDialog: state.setErrorPassword }))
    const [searchParams, setSearchParams] = useSearchParams();
    const year = searchParams.get('year')
    const momentYear = year ? moment(year, 'YYYY') : moment()

    const startDate = momentYear.format('YYYY-01-01')
    const endDate = momentYear.format('YYYY-12-31')

    const page = searchParams.get('page') || 1
    const show = searchParams.get('show') || 50
    const keyword = searchParams.get('keyword')

    const browseHolidayDate = useBrowseHolidayDate({ expands: [], currentPage: page, keyword, startDate, endDate, sort: 'date', order: 'asc', pageSize: show })
    const deleteHolidayDate = useDeleteHolidayDate({ onSuccess: () => browseHolidayDate.refetch() })
    const pagination = transformResponseToPagination(browseHolidayDate.data)
    const holidayDates = transformResponseToList(browseHolidayDate.data)

    return (
        <div className="mb-3 mx-10">
            <div className="px-6 flex justify-between">
                <div>
                    <h1 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">
                        Tahun <span className="text-indigo-500">{momentYear.format('YYYY')}</span>
                    </h1>
                    <h2 className=" text-gray-500 text-xl -mt-2 font-medium space-x-2 whitespace-nowrap mb-3">
                        Daftar Hari Libur
                    </h2>
                </div>
                <div>
                    <ListYear />
                </div>

            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5  md:rounded-lg">
                {browseHolidayDate.isLoading ? <Loader.TableLoader /> :
                    <Table.Data
                        items={holidayDates}
                        keySelector={item => item.ID}
                        columns={[
                            {
                                header: {
                                    name: 'Tanggal',
                                },
                                value: {
                                    select: (item) => item.date,
                                    renderer: Table.Renderer.Raw,
                                    renderExtra: {
                                        render: ({ value }) => {
                                            return (
                                                <div>
                                                    {humanizeDate(value)}
                                                </div>
                                            )
                                        }
                                    }
                                },
                                option: {
                                    primary: true,
                                    showOn: 'xs'
                                }
                            },
                            {
                                header: {
                                    name: 'Judul',
                                },
                                value: {
                                    select: (item) => item.title,
                                    renderer: Table.Renderer.Raw,
                                    renderExtra: {
                                        render: ({ value }) => {
                                            return (
                                                <div>
                                                    {value}
                                                </div>
                                            )
                                        }
                                    }
                                },
                                option: {
                                    showOn: 'xl'
                                }

                            },
                        ]}

                        actions={[
                            Table.Action.HardDelete({
                                confirm: (item) => confirmDialog({
                                    title: `Hapus Hari Libur ${item.date} (${item.title})`,
                                    message: 'Apakah anda yakin??',
                                    icon: Icon.IconPhospor.TrashSimple,
                                    iconColor: 'rose',
                                    onConfirm: (password) => deleteHolidayDate.mutateAsync({ ID: item.ID, password }),
                                    usingPassword: true,
                                }),
                            }),
                        ]}

                    />
                }

            </div>

            <Pagination
                pagination={pagination}
                onPaginationChange={({ page, show }) => setSearchParams(SearchParams.sanitize(SearchParams.updates(searchParams, { show, page })))}
            />

        </div>
    )
}