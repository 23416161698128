import { useCountDown } from "Common/Utility/hook";
import moment from "moment";
import { useEffect, useState } from "react";

const CountUp = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        var timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    });

    function tick() {
        setDate(new Date());
    }

    const dateAsMoment = moment(date)

    const render = ({ hour, minute, second }) => (
        <span className="countdown font-mono text-2xl">
            <span style={{ "--value": hour }}></span>:
            <span style={{ "--value": minute }}></span>:
            <span style={{ "--value": second }}></span>
        </span>
    )


    return render({ hour: dateAsMoment.format('HH'), minute: dateAsMoment.format('mm'), second: dateAsMoment.format('ss') })
}

const CountDown = ({ interval, onComplete }) => {
    const [count] = useCountDown(interval, { onComplete });

    const render = ({ count }) => {
        const duration = moment.duration(count, 'seconds')
        return (
            <span className="countdown font-mono text-2xl">
                <span style={{ "--value": duration.hours() }}></span>:
                <span style={{ "--value": duration.minutes() }}></span>:
                <span style={{ "--value": duration.seconds() }}></span>
            </span>
        )
    }

    if (count <= 0) {
        return (
            <span className="flex text-center justify-center text-rose-600">Time Out</span>
        )
    }


    return render({ count })
}

export default { CountUp, CountDown }