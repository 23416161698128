import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import { TODAY } from 'Common/Constant/Date'
import Icon from 'Common/UI/Component/Icon'
import moment from 'moment'
import Input from 'Common/UI/Component/Input'
import { useCreateAttendance, useDateAttendance } from 'Core/HumanResource/API/AttendanceAPI'
import { transformResponseToSingle } from 'Common/Utility/formatter'
import Button from 'Common/UI/Component/Button'

export const useCreateAttendanceDialogStore = create((set) => ({
    show: false,
    date: TODAY,

    setShow: (value) => set(() => ({ show: value })),
    confirm: ({ date }) => {
        let update = { show: true, date }
        return set(() => update)
    },
}))

export function CreateAttendanceActivityDialog({ onConfirm = () => null }) {
    const show = useCreateAttendanceDialogStore(state => state.show)
    const { date } = useCreateAttendanceDialogStore(state => ({ date: state.date }), shallow)
    const setShow = useCreateAttendanceDialogStore(state => state.setShow)

    const selectedDateAttendance = useDateAttendance({ date: date })
    const dateAttendance = transformResponseToSingle(selectedDateAttendance.data)

    const [morningActivity, setMorningActivity] = useState('')
    const [noonActivity, setNoonActivity] = useState('')
    const [afterNoonActivity, setAfterNoonActivity] = useState('')

    const createAttendance = useCreateAttendance({
        onSuccess: (response) => {
            onConfirm(transformResponseToSingle(response))
            setShow(false)
        }
    })

    const cancelButtonRef = useRef(null)
    const dateAsMoment = moment(date, 'YYYY-MM-DD')

    useEffect(() => {
        setMorningActivity(dateAttendance?.activities.find(activity => activity.type === 1)?.activity)
        setNoonActivity(dateAttendance?.activities.find(activity => activity.type === 2)?.activity)
        setAfterNoonActivity(dateAttendance?.activities.find(activity => activity.type === 3)?.activity)
    }, [dateAttendance, date])



    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setShow}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
                            <div>
                                <div className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-lime-100`}>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                        <Icon.IconPhospor.Notebook className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                </div>


                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Isi Log Harian untuk {dateAsMoment.format('dddd, D MMMM Y')}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Silahkan isi log harian anda.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-7 grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-3'>
                                <span className="p-float-label">
                                    <Input.InputTextarea id="morningActivity" value={morningActivity} onChange={(e) => setMorningActivity(e.target.value)} rows={5} cols={30} className='w-full' />
                                    <label htmlFor="morningActivity">Kegiatan Pagi Hari</label>
                                </span>
                                <span className="p-float-label">
                                    <Input.InputTextarea id="noonActivity" value={noonActivity} onChange={(e) => setNoonActivity(e.target.value)} rows={5} cols={30} className='w-full' />
                                    <label htmlFor="noonActivity">Kegiatan Siang Hari</label>
                                </span>
                                <span className="p-float-label">
                                    <Input.InputTextarea id="afterNoonActivity" value={afterNoonActivity} onChange={(e) => setAfterNoonActivity(e.target.value)} rows={5} cols={30} className='w-full' />
                                    <label htmlFor="afterNoonActivity">Kegiatan Sore Hari</label>
                                </span>
                            </div>
                            <div className="flex justify-end gap-1.5">
                                <Button.LoadableButton
                                    variant='secondary'
                                    type="button"
                                    onClick={() => setShow(false)}
                                    ref={cancelButtonRef}
                                >
                                    Batal
                                </Button.LoadableButton>
                                <Button.LoadableButton
                                    type='submit'
                                    loading={createAttendance.isLoading}
                                    loadingText='Memproses..'
                                    onClick={() => {
                                        createAttendance.mutateAsync({ date, morningActivity, afterNoonActivity, noonActivity })
                                    }}
                                >
                                    Simpan
                                </Button.LoadableButton>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}