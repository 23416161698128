export const attendanceReports = [
    {
        Month: "Jan 21",
        "Check Out": 28,
        "Check In": 24,
        "Dinas": 49,
    },
    {
        Month: "Feb 21",
        "Check Out": 18,
        "Check In": 13,
        "Dinas": 29,
    },
    {
        Month: "Mar 21",
        "Check Out": 21,
        "Check In": 19,
        "Dinas": 16,
    },
    {
        Month: "Apr 21",
        "Check Out": 34,
        "Check In": 39,
        "Dinas": 21,
    },
    {
        Month: "May 21",
        "Check Out": 21,
        "Check In": 48,
        "Dinas": 21,
    },
    {
        Month: "Jun 21",
        "Check Out": 31,
        "Check In": 38,
        "Dinas": 51,
    },
    {
        Month: "Jul 21",
        "Check Out": 34,
        "Check In": 43,
        "Dinas": 38,
    },
    {
        Month: "Aug 21",
        "Check Out": 21,
        "Check In": 41,
        "Dinas": 31,
    },
    {
        Month: "Sep 21",
        "Check Out": 33,
        "Check In": 49,
        "Dinas": 19,
    },
    {
        Month: "Oct 21",
        "Check Out": 15,
        "Check In": 12,
        "Dinas": 23,
    },
    {
        Month: "Nov 21",
        "Check Out": 33,
        "Check In": 26,
        "Dinas": 48,
    },
    {
        Month: "Dec 21",
        "Check Out": 27,
        "Check In": 34,
        "Dinas": 29,
    },
    {
        Month: "Jan 22",
        "Check Out": 38,
        "Check In": 29,
        "Dinas": 26,
    },
];