import { Route, Routes, Navigate } from "react-router-dom";
import MyTokenRoutes from 'Core/Authentication/UseCase/Route/MyToken/MyTokenRoutes';

export default function GuestRouters() {
    return (
        <Routes>
            <Route path="/" element={<MyTokenRoutes.LoginRoute />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}