import Icon from "Common/UI/Component/Icon";
import { Disclosure, Menu, Switch, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchParams } from "Common/Utility/search-params";
import { Pagination } from "Common/UI/Block/Pagination";


export default function BrowsePaginateDataLayout({ title = "Jelajah Data", subtitle = "Halaman pencarian, manajemen, pengaturan data terkait.", children, statistics = [], sidebar = null, filterOptions = [], sortOptions = [], pagination, onReset = (setSearchParams) => setSearchParams({}), loading = false, pageSizeSelections = [5, 10, 20, 50, 100] }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [keywordValue, setKeywordValue] = useState(searchParams.get('keyword'))
    const search = (event) => {
        event.preventDefault()
        setSearchParams(SearchParams.sanitize(SearchParams.updates(searchParams, { keyword: keywordValue })))
    }

    return (
        <div>
            <div className="bg-white">
                <div className="container px-6 mx-auto flex flex-col md:flex-row items-start md:items-center justify-between pb-4 ">
                    <div className="my-2">
                        <h4 className="text-2xl font-bold leading-tight text-secondary">{title}</h4>
                        <span className="font-sans text-secondary/60">{subtitle}</span>
                        {(statistics || []).length === 0 ? null :
                            <ul className="flex flex-col md:flex-row items-start md:items-center text-gray-600 text-sm mt-3">
                                {(statistics || []).map((statistic, statisticIndex) => (
                                    <li key={statisticIndex} className="flex items-center mr-4">
                                        <div className="mr-1">
                                            {React.createElement(statistic.icon, { className: 'w-3 h-3' })}
                                        </div>
                                        <span>{statistic.value}</span>
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>
                    {!sidebar ? null :
                        <div className="mt-6 md:mt-0">
                            {sidebar}
                        </div>
                    }
                </div>
            </div>
            <div className="bg-white shadow-md">
                <Disclosure
                    as="section"
                    aria-labelledby="filter-heading"
                    className="relative z-10 border-t border-b border-gray-200 grid items-center"
                >
                    <h2 id="filter-heading" className="sr-only">
                        Filters
                    </h2>
                    <div className="relative col-start-1 row-start-1 py-4">
                        <div className="max-w-7xl mx-auto flex space-x-6 divide-x divide-gray-200 text-sm px-4 sm:px-6 lg:px-8">
                            <div className="flex items-center">
                                <Disclosure.Button className="group text-gray-700 font-medium flex items-center">
                                    <Icon.IconPhospor.Funnel
                                        className="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    <span className="hidden sm:block">Filter</span>
                                </Disclosure.Button>
                            </div>
                            <form onSubmit={search} className="pl-6 flex flex-1 w-full">
                                <div className="mt-1 relative flex items-center w-full">
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        placeholder="Quick search"
                                        value={keywordValue}
                                        onChange={({ target: { value } }) => setKeywordValue(value)}
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                        <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                                            ⌘ Enter
                                        </kbd>
                                    </div>
                                </div>
                            </form>
                            <div className="col-start-1 row-start-1 py-4">
                                <div className="flex justify-end max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <Menu as="div" className="relative inline-block">
                                        <div className="flex">
                                            <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                                <span className="hidden sm:block">Sort</span>
                                                <Icon.IconPhospor.SortAscending
                                                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-2xl backdrop-filter backdrop-blur-sm ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
                                                <div className="py-1">
                                                    {sortOptions.map((sortOption) => (
                                                        <Menu.Item key={sortOption.name}>
                                                            {({ active }) => (
                                                                <button
                                                                    onClick={() => setSearchParams({ ...sortOption.parameters })}
                                                                    className={classNames(
                                                                        SearchParams.isExactMatch(searchParams, sortOption.parameters) ? 'font-medium text-gray-900' : 'text-gray-500',
                                                                        active ? 'bg-gray-100' : '',
                                                                        'block px-4 py-2 text-sm w-full text-left'
                                                                    )}
                                                                >
                                                                    {sortOption.name}
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative">
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel className="border-t border-gray-200 py-10 shadow backdrop-filter backdrop-blur-md absolute top-0 right-0 left-0">
                                <div className="max-w-7xl mx-auto gap-x-4 px-4 text-sm sm:px-6 lg:px-8 grid grid-cols-1 gap-y-10 auto-rows-min md:grid-cols-4 lg:grid-col-6 md:gap-x-6">
                                    {filterOptions.map((filterOption, filterOptionIndex) => (
                                        <fieldset key={filterOptionIndex}>
                                            <legend className="block font-medium">{filterOption.name}</legend>
                                            <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                                                {filterOption.options.map((option) => {
                                                    const selected = SearchParams.isExactMatch(searchParams, option.parameters)
                                                    return (
                                                        <div key={option.name} className="flex items-center text-base sm:text-sm">
                                                            <Switch
                                                                checked={selected}
                                                                onChange={(checked) => SearchParams.toggle(setSearchParams, searchParams, option.parameters, checked, filterOption.multiple)}
                                                                className={classNames(
                                                                    selected ? 'bg-indigo-600' : 'bg-gray-200',
                                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                                )}
                                                            >
                                                                <span className="sr-only">Use setting</span>
                                                                <span
                                                                    className={classNames(
                                                                        selected ? 'translate-x-5' : 'translate-x-0',
                                                                        'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                                    )}
                                                                >
                                                                    <span
                                                                        className={classNames(
                                                                            selected ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                                            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    >
                                                                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                                                            <path
                                                                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                                stroke="currentColor"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span
                                                                        className={classNames(
                                                                            selected ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                                            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    >
                                                                        <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                                                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </Switch>
                                                            <label className="ml-3 min-w-0 flex-1 text-gray-600">
                                                                {option.name}
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </fieldset>

                                    ))}
                                </div>
                            </Disclosure.Panel>
                        </Transition>
                    </div>

                </Disclosure>
            </div>
            <section className="pt-10">
                {children}
            </section>
            {pagination == null ? null :
                <Pagination
                    pageSizeSelections={pageSizeSelections}
                    pagination={pagination}
                    onPaginationChange={({ show, page }) => setSearchParams(SearchParams.sanitize(SearchParams.updates(searchParams, { show, page })))}
                />
            }
        </div>
    )
}