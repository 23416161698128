import { requestDelete, requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useCreateReport({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ personName, title, description, location, date, time, files }) => {
            const path = `/whistleblow/whistleblow/report`;
            return requestPost(path, { personName, title, description, location, date, time, 'files[]': files })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useBrowseReport({ status, pageSize, currentPage, onSuccess, sort = 'datetime', order = 'asc', expands = [] }) {
    const path = `/whistleblow/whistleblow/report`
    const data = useQuery({
        queryKey: [path, status, pageSize, currentPage, sort, order, expands],
        queryFn: () => requestGet(path, { status, pageSize, currentPage, sort, order, expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useDetailReport(ID, { expands, onSuccess, onError }) {
    const path = `/whistleblow/whistleblow/report/${ID}`
    const data = useQuery({
        queryKey: [path, expands],
        queryFn: () => requestGet(path, { expand: expands.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}


export function useUpdateReport({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ ID, personName, title, description, location, date, time, status }) => {
            const path = `/whistleblow/whistleblow/report/${ID}`;
            return requestPost(path, { personName, title, description, location, date, time, status })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useBrowseReportFile(ID, { onSuccess }) {
    const path = `/whistleblow/whistleblow/report/${ID}/file`
    const data = useQuery({
        queryKey: [path],
        queryFn: () => requestGet(path, {}),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useUploadReportFile(ID, { onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ file }) => {
            const path = `/whistleblow/whistleblow/report/${ID}/file`
            return requestPost(path, { file })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}

export function useDeleteReportFile(ID, { onSuccess = () => null, onError }) {
    const mutation = useMutation({
        mutationFn: ({ name }) => {
            const path = `/whistleblow/whistleblow/report/${ID}/file/${name}`

            requestDelete(path, {})
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}