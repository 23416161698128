import classNames from "classnames"
import { RadioGroup } from '@headlessui/react'
import Icon from "./Icon"


function Card({
    options, value, onChange,
    error = false,
    optionKeySelector = (_, index) => index,
    optionTitleSelector = (option) => option.title,
    optionDescriptionSelector = (option) => option.description,
    optionNoteSelector = (option) => option.note,
    compare = (a, b) => a?.title === b?.title,
    gridWrapperClassName = 'grid-cols-1 sm:grid-cols-3'
}) {

    return (
        <RadioGroup value={value} onChange={onChange} by={compare}>
            <div className={classNames('mt-4 grid gap-y-6  sm:gap-x-4', gridWrapperClassName)}>
                {options.map((option, index) => (
                    <RadioGroup.Option
                        key={optionKeySelector(option, index)}
                        value={option}
                        className={({ checked, active }) =>
                            classNames(
                                checked ? 'border-transparent' : 'border-gray-300',
                                error ? 'ring-1 ring-rose-500' : (active ? 'border-indigo-500 ring-2 ring-indigo-500' : ''),
                                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                            )
                        }
                    >
                        {({ checked, active }) => (
                            <>
                                <div className="flex-1 flex">
                                    <div className="flex flex-col">
                                        <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                            {optionTitleSelector(option)}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                            {optionDescriptionSelector(option)}
                                        </RadioGroup.Description>
                                        <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                            {optionNoteSelector(option)}
                                        </RadioGroup.Description>
                                    </div>
                                </div>
                                <Icon.IconSolid.CheckCircleIcon
                                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                    aria-hidden="true"
                                />
                                <div
                                    className={classNames(
                                        active ? 'border' : 'border-2',
                                        checked ? 'border-indigo-500' : 'border-transparent',
                                        'absolute -inset-px rounded-lg pointer-events-none'
                                    )}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}

export { Card as RadioCard }
export default { Card }