import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { RoleAbilities, useAuthentication } from 'Common/Provider/Authentication'
import { useDetailEmployeeContext } from './Context'
import ModalAssignAccount from './UI/ModalAssignAccount'
import ModalAddEmployeeSalary from './UI/ModalAddEmployeeSalary'
import ModalInvokeEmployee from './UI/ModalInvokeEmployee'
import HeaderCard from './UI/HeaderCard'
import DetailEmployeeBrowseFile from './SubRoute/DetailEmployeeBrowseFile'
import DetailEmployeeBasicInformation from './SubRoute/DetailEmployeeBasicInformation'
import DetailEmployeeBrowseSalary from './SubRoute/DetailEmployeeBrowseSalary'
import DetailEmployeeAttendance from './SubRoute/DetailEmployeeAttendance'
import ModalCreateEmployeeBankAccount from './UI/ModalCreateEmployeeBankAccount'
import ModalDeleteEmployeeBankAccount from './UI/ModalDeleteEmployeeBankAccount'
import ModalCreateEmployeeContact from './UI/ModalCreateEmployeeContact'
import ModalDeleteEmployeeContact from './UI/ModalDeleteEmployeeContact'
import Icon from 'Common/UI/Component/Icon'
import { humanizeCurrency, humanizeDate, humanizeRelativeFromDate } from 'Common/Utility/humanize'
import { TabViewRoutes } from 'Common/UI/Block/TabViewRoutes'

export default function Route() {
    const { user } = useAuthentication()
    const { id, detailEmployee, employee, updateEmployee, setActionState, copyBankAccountNumber, actionState, browseEmployeeSalary, browseEmployeeBankAccount, selectActionObject, selectedActionObject } = useDetailEmployeeContext()

    if (detailEmployee.isLoading) {
        return null
    }

    return (
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
            <main className="py-10 w-full">
                {/* Page header */}
                <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {employee.avatar ?
                                    <img
                                        className="h-16 w-16 rounded-full"
                                        src={employee.avatar.fullURL}
                                        alt=""
                                    /> :
                                    <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                        <span className={classNames("font-medium leading-none text-white ")}>
                                            {employee.name.split(" ").map((part) => `${part[0]}`.toLocaleUpperCase()).join("")}
                                        </span>
                                    </span>
                                }
                            </div>
                        </div>
                        <div className=''>
                            <h1 className={classNames("text-lg md:text-2xl font-bold text-gray-900", employee.inActiveDate ? 'line-through' : '')}>{employee.name}</h1>

                            <p className="text-sm font-medium text-gray-500 flex flex-col lg:flex-row gap-2">

                                <span className='flex flex-row  gap-2'>
                                    <Icon.IconOutline.IdentificationIcon className='text-indigo-500 w-5 h-5' /> NIK {'  '}
                                    <span className='font-light'>{employee.nationalIdentityNumber}</span>
                                </span>

                                <span className='flex flex-row  gap-2'>
                                    <Icon.IconOutline.IdentificationIcon className='text-indigo-500 w-5 h-5' /> NIP {'  '}
                                    <span className='font-light'>{employee.employeeIdentityNumber}</span>
                                </span>
                            </p>
                            {employee.access && (
                                <button className="mt-1 relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                        <span
                                            className={classNames('bg-green-500', 'h-1.5 w-1.5 rounded-full')}
                                            aria-hidden="true"
                                        />
                                    </span>
                                    <span className="ml-3.5 font-medium text-gray-900">Access</span>
                                </button>
                            )}
                        </div>
                    </div>

                    {
                        RoleAbilities.isAbleEditEmployee(user) &&
                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                            <Menu as="div" className="relative text-left inline-block lg:hidden">
                                <div>
                                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                        Menu
                                        <Icon.IconSolid.ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-40">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link

                                                        to={`/employee/${id}/update`}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'group flex items-center px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <Icon.IconSolid.PencilIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                        Ubah
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => updateEmployee.mutate({ isInActive: employee.inActiveDate ? false : true })}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'group flex items-center px-4 py-2 text-sm gap-4'
                                                        )}
                                                    >
                                                        {employee.inActiveDate ? <Icon.IconSolid.RocketLaunchIcon className='w-4 h-4 text-gray-400' /> : <Icon.IconSolid.TrashIcon className='w-4 h-4 text-gray-400' />}
                                                        {employee.inActiveDate ? 'Aktifkan' : 'Nonaktifkan'}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                            <button
                                type="button"
                                onClick={() => updateEmployee.mutate({ isInActive: employee.inActiveDate ? false : true })}
                                className={classNames(
                                    "hidden lg:inline-flex  gap-1 items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100",
                                    employee.inActiveDate ? "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500" : "bg-rose-600 hover:bg-rose-700 focus:ring-rose-500"
                                )}
                            >
                                {employee.inActiveDate ? <Icon.IconSolid.RocketLaunchIcon className='w-4 h-4 text-white' /> : <Icon.IconSolid.TrashIcon className='w-4 h-4 text-white' />}
                                {employee.inActiveDate ? 'Aktifkan' : 'Nonaktifkan'}
                            </button>
                            <Link
                                to={`/employee/${id}/update`}
                                className="hidden lg:inline-flex  items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                            >
                                Ubah
                            </Link>
                        </div>
                    }

                </div>
                <div>
                    <Disclosure>
                        {({ open }) => (
                            <Fragment>
                                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                    <HeaderCard
                                        label={`Bergabung sejak ${humanizeDate(employee.created)}`}
                                        value={humanizeRelativeFromDate(employee.created)}
                                        icon={Icon.IconSolid.CalendarDaysIcon}
                                    />
                                    <HeaderCard
                                        label={`Pendidikan terakhir`}
                                        value={(employee.educations.length ? employee.educations[employee.educations.length-1] : null)?.name || '-'}
                                        icon={Icon.IconSolid.BuildingLibraryIcon}
                                    />

                                </dl>
                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Disclosure.Panel as="dl" className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                        <HeaderCard
                                            label={`Status Pernikahan`}
                                            value={employee.marital?.code || '-'}
                                            icon={Icon.IconSolid.HeartIcon}
                                        />
                                        {employee.salary && RoleAbilities.isAbleManageEmployeeSalary(user, employee) && (
                                            <HeaderCard
                                                label={`Gaji terakhir`}
                                                value={humanizeCurrency(employee.salary.totalAmount)}
                                                icon={Icon.IconSolid.BanknotesIcon}
                                                actionName={(
                                                    RoleAbilities.isAbleEditEmployee(user) &&
                                                    <div className='w-full flex items-center gap-4'>
                                                        <span className='flex flex-row gap-2'>
                                                            <Icon.IconSolid.PencilSquareIcon className='w-5 h-5' />
                                                            Ubah
                                                        </span>
                                                        <span onClick={() => setActionState('add-employee-salary')} className='flex flex-row gap-2'>
                                                            <Icon.IconSolid.PlusCircleIcon className='w-5 h-5' />
                                                            Buat
                                                        </span>
                                                    </div>
                                                )}
                                            />
                                        )}
                                        {!employee.salary && RoleAbilities.isAbleManageEmployeeSalary(user, employee) && (
                                            <HeaderCard
                                                label={`Gaji terakhir`}
                                                value={'-'}
                                                icon={Icon.IconSolid.BanknotesIcon}
                                                action={() => setActionState('add-employee-salary')}
                                                actionName={(
                                                    RoleAbilities.isAbleEditEmployee(user) &&
                                                    <div className='flex items-center gap-2'>
                                                        <Icon.IconSolid.PlusCircleIcon className='w-5 h-5' />
                                                        Buat
                                                    </div>
                                                )}
                                            />
                                        )}
                                        {employee.bankAccounts.map(bankAccount => (
                                            <HeaderCard
                                                key={bankAccount.ID}
                                                label={`Akun rekening ${bankAccount.bank.name}`}
                                                value={bankAccount.number}
                                                icon={Icon.IconSolid.CreditCardIcon}
                                                action={() => copyBankAccountNumber(bankAccount.number)}
                                                actionName={(

                                                    RoleAbilities.isAbleEditEmployee(user) &&
                                                    <div className='flex items-center gap-2'>
                                                        <Icon.IconSolid.ClipboardIcon className='w-5 h-5' />
                                                        Salin
                                                    </div>
                                                )}
                                            />
                                        ))}
                                    </Disclosure.Panel>
                                </Transition>
                                <Disclosure.Button as='div' className="relative mt-5">

                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <button
                                            type="button"
                                            className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <Icon.IconSolid.ChevronDoubleDownIcon className={classNames("-ml-1.5 mr-1 h-5 w-5 text-gray-400", open ? 'rotate-180 transform' : '')} />
                                            <span>Lihat lebih banyak</span>
                                        </button>
                                    </div>


                                </Disclosure.Button>
                            </Fragment>
                        )}
                    </Disclosure>
                </div>

                <div className={classNames(employee.inActiveDate ? 'opacity-40 hover:opacity-100' : '')}>
                    <TabViewRoutes
                        basePath={`/human-resource/employee/employee/${id}`}
                        routes={[
                            { to: ``, label: 'Informasi Dasar', icon: { solid: Icon.IconSolid.IdentificationIcon, outline: Icon.IconOutline.IdentificationIcon }, element: DetailEmployeeBasicInformation },
                            { to: `/attendance`, label: 'Presensi', icon: { solid: Icon.IconSolid.CalendarDaysIcon, outline: Icon.IconOutline.CalendarDaysIcon }, element: DetailEmployeeAttendance },
                            ...(RoleAbilities.isAbleManageEmployeeSalary(user, employee) ? [{ to: `/salary`, label: 'Sejarah Gaji', icon: { solid: Icon.IconSolid.BanknotesIcon, outline: Icon.IconOutline.BanknotesIcon }, element: DetailEmployeeBrowseSalary }] : []),
                            { to: `/file`, label: 'Berkas', icon: { solid: Icon.IconSolid.FolderIcon, outline: Icon.IconOutline.FolderIcon }, element: DetailEmployeeBrowseFile },
                        ]}
                    />
                </div>

                <ModalAssignAccount
                    employee={employee}
                    open={actionState === 'assign-account'}
                    onClose={() => setActionState(null)}
                    onConfirm={() => {
                        detailEmployee.refetch()
                        browseEmployeeSalary.refetch()
                        setActionState(null)
                    }}
                />

                <ModalAddEmployeeSalary
                    employee={employee}
                    open={actionState === 'add-employee-salary'}
                    onClose={() => setActionState(null)}
                    onConfirm={() => {
                        detailEmployee.refetch()
                        browseEmployeeSalary.refetch()
                        setActionState(null)
                    }}
                />


                <ModalCreateEmployeeBankAccount
                    employee={employee}
                    open={actionState === 'add-employee-bank-account'}
                    onClose={() => setActionState(null)}
                    onConfirm={() => {
                        detailEmployee.refetch()
                        browseEmployeeBankAccount.refetch()
                        setActionState(null)
                    }}
                />
                <ModalCreateEmployeeContact
                    employee={employee}
                    open={actionState === 'add-employee-contact'}
                    onClose={() => setActionState(null)}
                    onConfirm={() => {
                        detailEmployee.refetch()
                        browseEmployeeBankAccount.refetch()
                        setActionState(null)
                    }}
                />

                {selectedActionObject && (
                    <ModalDeleteEmployeeBankAccount
                        employee={employee}
                        bankAccount={selectedActionObject}
                        open={actionState === 'remove-employee-bank-account' && selectedActionObject !== null}
                        onClose={() => setActionState(null) || selectActionObject(null)}
                        onConfirm={() => {
                            detailEmployee.refetch()
                            selectActionObject(null)
                            setActionState(null)
                        }}
                    />
                )}
                {selectedActionObject && (
                    <ModalDeleteEmployeeContact
                        employee={employee}
                        employeeContact={selectedActionObject}
                        open={actionState === 'remove-employee-contact' && selectedActionObject !== null}
                        onClose={() => setActionState(null) || selectActionObject(null)}
                        onConfirm={() => {
                            detailEmployee.refetch()
                            selectActionObject(null)
                            setActionState(null)
                        }}
                    />
                )}
                {employee.access && (
                    <ModalInvokeEmployee
                        account={employee.access}
                        open={actionState === 'invoke-employee'}
                        onClose={() => setActionState(null)}
                        onConfirm={() => {
                            detailEmployee.refetch()
                            setActionState(null)
                        }}
                    />
                )}
            </main>
        </div>
    )
}