import { requestGet, requestPost } from "Common/Provider/RestClient"
import { useMutation, useQuery } from "@tanstack/react-query"

export function useCalendarAttendance({ onSuccess, startDate, endDate, employeeID, properties = [], type = null }) {
    const path = `/human-resource/attendance/attendance/calendar`
    const data = useQuery({
        queryKey: [path, type, startDate, endDate, employeeID, properties.join(',')],
        queryFn: () => requestGet(path, { startDate, endDate, employeeID, properties: properties.join(','), }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useDateAttendance({ onSuccess, enabled=true, date, employeeID, expand, properties = [] }) {
    const path = `/human-resource/attendance/attendance/${date}`
    const data = useQuery({
        queryKey: [path, enabled, expand],
        enabled: enabled,
        queryFn: () => requestGet(path, { employeeID, expand, properties: properties.join(',') }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}

export function useCreateAttendance({ onSuccess, onError }) {
    const mutation = useMutation({
        mutationFn: ({ date, checkIn, checkOut, morningActivity, noonActivity, afterNoonActivity }) => {
            const path = `/human-resource/attendance/attendance`
            return requestPost(path, {
                date, in: checkIn, out: checkOut,
                'activity[1][description]': morningActivity,
                'activity[2][description]': noonActivity,
                'activity[3][description]': afterNoonActivity,
            })
        },
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
        onError: (error) => onError ? onError(error) : null,
    })
    return mutation
}


export function useDownloadAvailableEmployeeAttendance({ onSuccess, token, start, employeeID }) {
    const path = `/service/download/attendance/employee-attendance/available`
    const data = useQuery({
        queryKey: [path, token, start, employeeID],
        queryFn: () => requestGet(path, { token, start, employeeID }),
        onSuccess: (response) => onSuccess ? onSuccess(response) : null,
    })
    return data
}
