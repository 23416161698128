import { Badge, Title } from "@tremor/react";
import { useAuthentication } from "Common/Provider/Authentication";
import { useConfirmDialogStore } from "Common/UI/Block/ConfirmDialog";
import { Pagination } from "Common/UI/Block/Pagination";
import Icon from "Common/UI/Component/Icon";
import Loader from "Common/UI/Component/Loader";
import Table from "Common/UI/Component/Table";
import { transformResponseToList, transformResponseToPagination, transformResponseToSingle } from "Common/Utility/formatter";
import { SearchParams } from "Common/Utility/search-params";
import { useCalendarAttendance } from "Core/HumanResource/API/AttendanceAPI";
import { useBrowseEmployee } from "Core/HumanResource/API/EmployeeAPI";
import moment from "moment";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function TableEmployeeAttendance({ }) {
    const { role } = useAuthentication()
    const [searchParams, setSearchParams] = useSearchParams();
    const attendanceDate = searchParams.get('attendance.date')


    const momentAttendanceDate = attendanceDate ? moment(attendanceDate, 'YYYY-MM-DD') : moment()
    const date = momentAttendanceDate.format('YYYY-MM-DD')


    const page = searchParams.get('page') || 1
    const show = searchParams.get('show') || 20
    const roleSearch = searchParams.get('employee.role')
    const haveAccess = searchParams.get('employee.haveAccess')
    const sort = searchParams.get('employee.sort') || 'name'
    const order = searchParams.get('employee.order') || 'asc'
    const isInActive = searchParams.get('employee.isInActive')
    const keyword = searchParams.get('employee.keyword')

    const browseEmployee = useBrowseEmployee({ expands: ['access', 'accessRole', 'position', 'salary'], currentPage: page, keyword, haveAccess, sort, order, isInActive, pageSize: show })
    const pagination = transformResponseToPagination(browseEmployee.data)
    const employees = transformResponseToList(browseEmployee.data)
    const { confirmDialog, setErrorPasswordConfirmDialog } = useConfirmDialogStore(state => ({ confirmDialog: state.confirm, setErrorPasswordConfirmDialog: state.setErrorPassword }))

    const calenderAttendance = useCalendarAttendance({ type: 'cross-employee', startDate: date, endDate: date, properties: ['attendances', 'duties', 'isHoliday', 'attendanceRate'] })
    const calenderAttendances = transformResponseToSingle(calenderAttendance.data)

    useEffect(() => {
        browseEmployee.refetch()
    }, [page, show, roleSearch, haveAccess, sort, order, isInActive, attendanceDate])

    return (
        <div className="mb-3">
            <div className="px-6">
                <h1 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">
                    {momentAttendanceDate.format('dddd,')} <span className="text-indigo-500">{momentAttendanceDate.format('D MMM (YYYY)')}</span>
                </h1>
                <h2 className=" text-gray-500 text-xl -mt-2 font-medium space-x-2 whitespace-nowrap mb-3">
                    Laporan Presensi
                </h2>
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5  md:rounded-lg">
                {browseEmployee.isLoading || calenderAttendance.isLoading ? <Loader.TableLoader /> :
                    <Table.Data
                        items={employees}
                        keySelector={item => item.ID}
                        columns={[
                            {
                                header: {
                                    name: 'Nama dan Surel',
                                },
                                value: {
                                    select: (item) => item.name,
                                    renderer: Table.Renderer.User,
                                    renderExtra: {
                                        email: (item) => item.email,
                                        imageURL: (item) => item.access?.avatarURL,
                                    }
                                },
                                option: {
                                    primary: true,
                                    showOn: 'xs'
                                }
                            },
                            {
                                header: {
                                    name: 'PNS/PPNPN',
                                },
                                value: {
                                    select: (item) => item.employeeType,
                                    renderer: Table.Renderer.Raw,
                                    renderExtra: {
                                        render: ({ value }) => (
                                            <div>
                                                <Badge icon={Icon.IconPhospor.SealCheck} color={value === 'PNS' ? 'lime' : 'gray'}>{value}</Badge>
                                            </div>
                                        )
                                    }
                                },
                                option: {
                                    showOn: 'xl'
                                }

                            },
                            {
                                header: {
                                    name: 'Prensi Check In',
                                },
                                value: {
                                    select: (item) => {
                                        const attendanceForEmployee = calenderAttendances[date]?.attendances.find(attendance => attendance.employee?.ID === item.ID)
                                        return attendanceForEmployee?.clock.in ? attendanceForEmployee?.clock.in.created : '-'
                                    },
                                    renderer: Table.Renderer.Raw,
                                    renderExtra: {
                                        render: ({ value, item }) => {
                                            return (
                                                <div>
                                                    <Badge icon={value !== '-' ? Icon.IconPhospor.Clock : Icon.IconPhospor.Hexagon} color={value !== '-' ? 'lime' : 'gray'}>
                                                        {value !== '-' ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('H:mm:ss') : '-'}
                                                    </Badge>
                                                </div>
                                            )
                                        }
                                    }
                                },
                                option: {
                                    showOn: 'xl'
                                }

                            },
                            {
                                header: {
                                    name: 'Prensi Check Out',
                                },
                                value: {
                                    select: (item) => {
                                        const attendanceForEmployee = calenderAttendances[date]?.attendances.find(attendance => attendance.employee?.ID === item.ID)
                                        return attendanceForEmployee?.clock.out ? attendanceForEmployee?.clock.out.created : '-'
                                    },
                                    renderer: Table.Renderer.Raw,
                                    renderExtra: {
                                        render: ({ value, item }) => {
                                            return (
                                                <div>
                                                    <Badge icon={value !== '-' ? Icon.IconPhospor.Clock : Icon.IconPhospor.Hexagon} color={value !== '-' ? 'lime' : 'gray'}>
                                                        {value !== '-' ? moment(value, 'YYYY-MM-DD HH:mm:ss').format('H:mm:ss') : '-'}
                                                    </Badge>
                                                </div>
                                            )
                                        }
                                    }
                                },
                                option: {
                                    showOn: 'xl'
                                }

                            },
                            {
                                header: {
                                    name: 'Dinas',
                                },
                                value: {
                                    select: (item) => {
                                        const dutyDatesForEmployes = calenderAttendances[date]?.duties.filter(duty => duty.employee?.ID === item.ID)
                                        return dutyDatesForEmployes.length
                                    },
                                    renderer: Table.Renderer.Raw,
                                    renderExtra: {
                                        render: ({ value, item }) => {
                                            const dutyDatesForEmployees = calenderAttendances[date]?.duties.filter(dutyDatesForEmployee => {
                                                return dutyDatesForEmployee.employee?.email === item.email
                                            })

                                            return (
                                                <div className="flex flex-wrap gap-1.5">
                                                    <Badge icon={Icon.IconPhospor.AirplaneTakeoff} color={'amber'}>
                                                        {value}
                                                    </Badge>
                                                    {dutyDatesForEmployees.map(dutyDatesForEmployee => (
                                                        <Badge key={dutyDatesForEmployee.ID} icon={Icon.IconPhospor.Briefcase} color={'yellow'}>
                                                            {dutyDatesForEmployee.duty.number}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            )
                                        }
                                    }
                                },
                                option: {
                                    showOn: 'sm'
                                }

                            },
                        ]}

                        actions={[
                            Table.Action.ChatOnWhatsapp({ phoneNumberSelector: item => item.phoneNumber?.startsWith('0') ? `62${item.phoneNumber.substring(1)}` : item.phoneNumber, messageCompose: item => `Hai, ${item.name}` }),
                            Table.Action.View({ basePath: '/human-resource/employee/employee' }),
                        ]}

                    />
                }

            </div>

            <Pagination
                pagination={pagination}
                onPaginationChange={({ page, show }) => setSearchParams(SearchParams.sanitize(SearchParams.updates(searchParams, { show, page })))}
            />

        </div>
    )
}