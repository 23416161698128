import useEffectOnce from 'Common/Utility/useEffectOnce'
import { useDetailMyCredential } from 'Core/Authentication/API/MyCredentialAPI'
import { createContext, useContext } from 'react'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const INITAL_STATE = {
    user: null,
    token: null,
    role: null
}

export const ROLE_EMPLOYEE = 'Employee'
export const ROLE_SUPER_ADMINISTRATOR = 'SuperAdministrator'
export const ROLES = [
    { role: ROLE_EMPLOYEE, name: 'Karyawan' },
    { role: ROLE_SUPER_ADMINISTRATOR, name: 'Super Administrator' },
]

export const useAuthenticationStore = create(
    persist(
        (set, get) => ({
            ...INITAL_STATE,
            signinOut: () => set(() => (INITAL_STATE)),
            signinIn: (account, token, role) => set({ user: account, ...(token ? { token } : {}), ...(role ? { role } : {}) }),
            setRole: (role) => set({ role })
        }),
        {
            name: 'auth', // unique name
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        }
    )
)

const AuthenticationContext = createContext()
export function AuthenticationProvider({ children }) {
    const { signinOut, signinIn, user, token, role, setRole } = useAuthenticationStore()
    const detailMyCredential = useDetailMyCredential({
        enabled: false,
        onError: () => signinOut(),
        onSuccess: ({ data: response }) => {
            const data = response.data
            signinIn(data.account)
        }
    })

    let refreshAuth = () => {
        if (token) {
            detailMyCredential.refetch()
        } else {
            signinOut()
        }
    }

    const isRoleSwitchable = () => {
        if (!user) {
            return false
        }
        return [user.role.employee, user.role.superAdministrator].filter(role => role).length > 1
    }

    useEffectOnce(refreshAuth)

    return (
        <AuthenticationContext.Provider
            value={{
                user, token, role,
                signIn: signinIn, signOut: signinOut,
                refreshAuth, setRole: setRole,
                isRoleSwitchable
            }}>
            {children}
        </AuthenticationContext.Provider>
    )
}

export const authenticationState = useAuthenticationStore.getState()
export const useAuthentication = () => useContext(AuthenticationContext)

export const RoleAbilities = {
    isAbleEditEmployee: (user, role) => {
        return user && (
            role === ROLE_SUPER_ADMINISTRATOR ||
            (role == ROLE_EMPLOYEE && user.role.humanResourceOperator)
        )
    },

    isAbleShowEmployeeSalary: (user, role) => {
        return user && (
            role === ROLE_SUPER_ADMINISTRATOR ||
            (role == ROLE_EMPLOYEE && user.role.humanResourceOperator)
        )
    },
    isAbleManageEmployeeSalary: (user, role) => {
        return user && (
            role === ROLE_SUPER_ADMINISTRATOR ||
            (role == ROLE_EMPLOYEE && user.role.humanResourceOperator)
        )
    },
};
